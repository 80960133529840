import { Listbox, Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DisplaySessionDataList,
  SessionListAsync,
} from "redux/features/sessionSlice";
import {
  selectSelectedSessionId,
  setSelectedSessionId,
} from "redux/features/sessionSlice1";
import { store, useTypedSelector } from "redux/store";

const CustomSessionDropdown = ({ className, ...props }) => {
  const sessionSelected = useTypedSelector(selectSelectedSessionId);

  const sessions = useTypedSelector(DisplaySessionDataList);

  const dispatch = useDispatch<typeof store.dispatch>();

  const handleSessionChange = (selectedSession) => {
    dispatch(setSelectedSessionId(selectedSession?.id));
    localStorage.setItem("selectedSessionId", selectedSession?.id);
  };

  const [page, setPage] = useState(1);
  const [pageSize] = useState(100);

  useEffect(() => {
    dispatch(SessionListAsync({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  return (
    <div
      className={`right-side col-md-3 custom-session-dropdown ${className}`}
      {...props}
    >
      <form action="" className="min-w-40">
        <div className="form-group">
          <label htmlFor="">Session Name</label>
          <Listbox value={sessionSelected} onChange={handleSessionChange}>
            <div className="relative">
              <Listbox.Button className="relative text-left">
                <span className="block truncate">
                  {sessionSelected
                    ? sessions.find((item) => item?.id === sessionSelected)
                        ?.name
                    : "Select Session"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-0 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {sessions?.map((session, sessionIdx) => (
                    <Listbox.Option
                      key={sessionIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-8 pr-4 ${
                          active
                            ? "bg-orange-100 text-orange-700"
                            : "text-gray-900"
                        }`
                      }
                      value={session}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {session?.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </form>
    </div>
  );
};

export default CustomSessionDropdown;
