import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import CustomSessionDropdown from "components/customSessionDropdown";
import React, { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import "./style.scss";
import {
  schoolAdminLoginForReportsAsync,
  selectStaffWiseDataList,
  staffWiseDataAsync,
} from "redux/features/reportSlice";
import {
  DisplaySchoolDataList,
  SchoolListAsync,
} from "redux/features/schoolSlice";
import { selectSelectedSessionId } from "redux/features/sessionSlice1";
import { store, useTypedSelector } from "redux/store";
import { SchoolAdminListAsync } from "redux/features/schoolAdminSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as yup from "yup";
import placeholderImage from "../../../../assets/img/placeholder.png";
import { Spinner } from "react-bootstrap";

const initialValues = {
  school_name: "",
};

const validationSchema = yup.object().shape({
  school_name: yup.string().required("Please select a school name"),
});

const TeacherReport = () => {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const sessionSelected = useTypedSelector(selectSelectedSessionId);
  const dispatch = useDispatch<typeof store.dispatch>();
  const staffWiseDataList = useTypedSelector(selectStaffWiseDataList);
  const SchoolDataList = useTypedSelector(DisplaySchoolDataList);
  const isLoading = useTypedSelector(
    (state) => state.staffWiseDataState.isLoading
  );

  useEffect(() => {
    dispatch(SchoolListAsync({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsFormSubmitted(true);
      resetChart();

      if (!selectedSchool) {
        formik.setFieldTouched("school_name", true, true);
        return;
      }

      if (!sessionSelected) {
        alert("Please select a session before viewing the report.");
        return;
      }

      try {
        const response = await dispatch(
          staffWiseDataAsync({
            session_id: sessionSelected,
          })
        );
        unwrapResult(response);
      } catch (error) {
        console.error("Failed to fetch staff-wise data:", error);
      }
    },
  });

  const { errors, touched, handleSubmit, setValues, resetForm } = formik;

  useEffect(() => {
    if (staffWiseDataList.length > 0) {
      const performanceData = staffWiseDataList.map((staff) =>
        parseFloat(staff.teacher_performance.replace("%", ""))
      );
      const staffNames = staffWiseDataList.map((staff) => staff.staff_name);

      setChartOptions({
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            endingShape: "rounded",
          },
        },
        xaxis: {
          categories: staffNames,
        },
        yaxis: {
          title: {
            text: "Performance (%)",
          },
          max: 100,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          y: {
            formatter: (val) => `${val}%`,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
      });

      setChartSeries([{ name: "Performance", data: performanceData }]);
    } else if (isFormSubmitted && staffWiseDataList.length === 0) {
      resetChart();
    }
  }, [staffWiseDataList, isFormSubmitted]);

  const resetChart = () => {
    setChartOptions({});
    setChartSeries([]);
  };

  const handleSchoolSelect = async (selectedSchool) => {
    setSelectedSchool(selectedSchool);

    setValues({
      school_name: selectedSchool?.name,
    });

    formik.validateField("school_name");

    try {
      const response = await dispatch(
        SchoolAdminListAsync({ schoolId: [selectedSchool.id] })
      );
      const result = unwrapResult(response);

      if (result?.data?.length > 0) {
        const { client_id, email } = result?.data[0];
        const loginResponse = await dispatch(
          schoolAdminLoginForReportsAsync({ client_id, email })
        );
        const loginResult = unwrapResult(loginResponse);

        if (loginResult?.access_token && loginResult?.db_token) {
          localStorage.setItem("reportToken", loginResult.access_token);
          localStorage.setItem("reportdb_token", loginResult.db_token);
        } else {
          console.error(
            "Login-as API failed to return valid tokens:",
            loginResult
          );
        }
      } else {
        console.error("No school admins found.");
      }
    } catch (error) {
      console.error("Error fetching school admin data or logging in:", error);
    }
  };

  const handleViewReport = () => {
    handleSubmit();
  };

  const handleReset = () => {
    setTimeout(() => {
      resetForm();
      setSelectedSchool(null);
      setIsFormSubmitted(false);
      resetChart();
    }, 500);
  };

  return (
    <>
      <div className="title">
        <h5>Staff performance Report</h5>
      </div>
      <div className={`left__ ${isLoading ? "blur-background" : ""}`}>
        <CustomSessionDropdown className="ml-4 mb-2 mt-2" />

        <form onSubmit={formik.handleSubmit}>
          <div className="form-group col-md-3 mb-3 ml-4">
            <label htmlFor="school_name">
              School Name <span className="form_required">*</span>
            </label>
            <Listbox value={selectedSchool} onChange={handleSchoolSelect}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md">
                  <span className="block truncate">
                    {selectedSchool
                      ? selectedSchool?.name
                      : "Select school name"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm pl-0 z-10">
                    {SchoolDataList?.length > 0 ? (
                      SchoolDataList?.map((school, index) => (
                        <Listbox.Option
                          key={index}
                          value={school}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-amber-100 text-amber-900"
                                : "text-gray-900"
                            }`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {school?.name}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))
                    ) : (
                      <div className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-700">
                        No Schools Found
                      </div>
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {errors.school_name && touched.school_name && (
              <div className="error">{errors.school_name}</div>
            )}
          </div>

          <div className="col-md-3 ml-4 mt-4">
            <button
              className="custom-active-button w-full rounded-md mb-2"
              type="button"
              onClick={handleViewReport}
            >
              View School Report
            </button>
          </div>
          <div className="col-md-3 ml-4 mt-2">
            <button
              className="custom-inactive-button w-full rounded-md mb-2"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
      <div className="__right">
        {isLoading ? (
          <div className="loading-overlay">
            <Spinner animation="border" />
            <h5>Fetching report...</h5>
          </div>
        ) : !isFormSubmitted ? (
          <div className="placeholder-section">
            <h5>
              Please select filters
              <br />
              for reports
            </h5>
            <img src={placeholderImage} alt="report placeholder" />
          </div>
        ) : chartSeries?.length === 0 ? (
          <div className="placeholder-section">
            <h5>No Record found</h5>
          </div>
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
          />
        )}
      </div>
    </>
  );
};

export default TeacherReport;
