import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import uuid from 'react-uuid';
import { closeIcon, downarrowIcon, uparrowIcon } from "../../assets";
import ExamAnalysisLogo from "../../assets/img/icons/ExamAnalysisLogo.png"
import {
  userRoutes,
  schoolAdminRoutes,
  superAdminRoutes,
} from "routes/AppRoutes";
import { useTypedSelector } from "redux/store";
import {
  selectIsMobileView,
  selectIsShowSidebar,
  updateIsShowSidebar,
  selectSidebarUpdateKey,
} from "redux/features/appStateSlice";
import { selectActiveModules, fetchActiveModulesAsync } from "redux/features/moduleWiseAccessSlice";
import { RouteType } from "routes/config";

// get sub-doamin through local storage //
const getSubdomain = () => {
  return localStorage.getItem("subdomain");
};


// sidebar component here //
const Sidebar = (props: any) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const isMobileView = useTypedSelector(selectIsMobileView);
  const activeModules = useTypedSelector(selectActiveModules);
  const sidebarUpdateKey = useSelector(selectSidebarUpdateKey);

  const [openedIndex, setOpenedIndex] = useState<number[]>([]);
  const [finalRoutes, setFinalRoutes] = useState<RouteType[]>([]);
  -
    useEffect(() => {
      dispatch(fetchActiveModulesAsync());
    }, [dispatch]);

  //  Filter Routes //
  const filterRoutes = useCallback((routes: RouteType[], activeModules: any[]) => {
    const role_id = localStorage.getItem("role_id")
    const flatModules = activeModules.find((ele) => ele.role_id == role_id);
    return routes.filter((route: RouteType) => {
      const activeModule = flatModules?.modules.find(module => module.module.toLowerCase() === route.state.toLowerCase());
      return activeModule && activeModule.module_permissions.some(perm => perm.action === 'view' && perm.status);
    });
  }, []);


  useEffect(() => {
    const subdomain = getSubdomain();
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    let routes: RouteType[] = subdomain || (SToken && Sdb_token)
      ? [...schoolAdminRoutes, ...userRoutes]
      : !subdomain && !(SToken && Sdb_token)
        ? [...superAdminRoutes, ...userRoutes]
        : [...userRoutes];

    const filteredRoutes = filterRoutes(routes, activeModules);

    setFinalRoutes(filteredRoutes);
  }, [activeModules, filterRoutes, sidebarUpdateKey]);


  const setSidebar = useCallback((show = false) => {
    dispatch(updateIsShowSidebar({ show }));
  }, [dispatch]);

  const onClickOpenSubMenu = useCallback((index: number) => {
    setOpenedIndex(prev => prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]);
  }, []);


  // Render Items //
  const renderItem = useCallback((route: RouteType, index: number) => (
    <li
      key={`${route.path}-${uuid()}-${index}`}
      className={
        pathname.includes(route.path) && !route?.children?.length
          ? "activeMenu"
          : pathname.includes(route.path) && route?.children?.length
            ? "activeSubMenu"
            : ""
      }
      onClick={() => route?.children?.length && onClickOpenSubMenu(index)}
    >
      <div onClick={() => !route?.children && navigate(route.path)}>
        {route.sidebarProps?.img}
        {route.sidebarProps?.displayText}
        {route?.children?.length && (
          <img
            src={openedIndex.includes(index) ? uparrowIcon : downarrowIcon}
            alt="arrow"
            className="arrow-shift"
          />
        )}
      </div>
    </li>
  ), [pathname, navigate, onClickOpenSubMenu, openedIndex]);


  // Tsx here //
  return (


    <div className={`sidebar ${(isShowSidebar || props.showComps) ? "open" : ""}`}>
      <span className="close-mob-icon" onClick={() => setSidebar()}>
        <img src={closeIcon} alt="close" />
      </span>

      {/* Logo */}
      <div>
        <img
          src={ExamAnalysisLogo}
          alt="logo"
          className="logo"
        />
      </div>

      {/* Final Routes here */}
      <ul>
        {finalRoutes?.map((route: RouteType, index: number) => (
          <div key={index}>
            {renderItem(route, index)}
            <Transition
              as={Fragment}
              show={openedIndex.includes(index)}
              enter="transition-opacity ease-linear duration-700"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="h-full w-full inner-menu">
                {route?.children?.map((childRoute: RouteType) => renderItem(childRoute, index))}
              </div>
            </Transition>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

// Sidebar components done ///
