import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  staffList: [],
  isLoading: false,
  error: null,
};

// StaffDetails here //
interface StaffDetails {
  gender: string;
  grade_id: number[];
  subject_id: string[];
  section_id: string[];
  phone: string;
  email: string;
  role_id: string;
  department: string;
  emp_id: string;
  staff_name: string;
}

// Create Staff Async here //
export const createStaffAsync = createAsyncThunk(
  "staff/create",
  async (credentials: StaffDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/staff/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Staff Slice here //

const staffSlice = createSlice({
  name: "staffState",
  initialState,
  reducers: {
    staffData: (state: any, action) => {
      state.staffList.push(action.payload);
    },
    resetStaffList: () => initialState,
    clearStaffError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStaffAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createStaffAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.staffList = [...state.staffList, action.payload];
      })
      .addCase(createStaffAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetStaffList, clearStaffError } = staffSlice.actions;
export default staffSlice.reducer;

export const selectStaffDataList = (state: RootState) =>
  state.staffState?.staffList ?? [];

// staff list //

interface ErrorPayload {
  message: string;
  status: number;
}

interface StaffListDetails {
  id: number;
  emp_id: string;
  staff_name: string;
  role: string;
  email: string;
  grades: string[];
  status: boolean;
}

interface StaffListState {
  staffDataList: StaffListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

const staffListInitialState: StaffListState = {
  staffDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

interface StaffListResponse {
  staff: StaffListDetails[];
  count: number;
}

interface FetchStaffArgs {
  gradeId?: number[];
  roleId?: number[];
  empIds?: string[];
  staffId?: number[];
  statusId?: boolean;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  isAllDataFetch?: boolean;
}

// Staff List Async here //

export const StaffListAsync = createAsyncThunk<
  StaffListResponse,
  FetchStaffArgs,
  { rejectValue: ErrorPayload }
>(
  "staff/create/list",
  async (
    {
      gradeId,
      roleId,
      empIds,
      staffId,
      statusId,
      searchQuery,
      page = 1,
      pageSize = 10,
      isAllDataFetch,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (roleId) filter.role_id = roleId;
      if (empIds && empIds.length > 0) filter.emp_id = empIds;
      if (staffId) filter.id = staffId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.staff_name = searchQuery;

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const response = await axios.post(
        `${API_BASE_URL}/staff/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken || reportToken}`,
            "db-token": db_token || Sdb_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const staff = response?.data?.data as StaffListDetails[];
        return { staff, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Staff List Slice here //

export const staffListSlice = createSlice({
  name: "staffListState",
  initialState: staffListInitialState,
  reducers: {
    clearStaffList: () => staffListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(StaffListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        StaffListAsync.fulfilled,
        (state, action: PayloadAction<StaffListResponse>) => {
          state.isLoading = false;
          state.staffDataList = action.payload.staff;
          state.count = action.payload.count;
        }
      )
      .addCase(StaffListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearStaffList } = staffListSlice.actions;
export const staffListReducer = staffListSlice.reducer;

export const DisplayStaffDataList = (state: RootState) =>
  state.staffListState.staffDataList ?? [];
export const selectStaffListCount = (state: RootState) =>
  state.staffListState.count;

// // update staff values//
interface ErrorPayload {
  message: string;
  status: number;
}

interface UpdateStaffDetails {
  [x: string]: any;
  gender: string;
  phone: string;
  department: string;
  emp_id: string;
  staff_name: string;
  role_id: string;
  data_json?: TeacherData[];
}

interface TeacherData {
  grade_id: number[];
  subject_id?: string[];
  section_id: string[];
  teacher_type: string;
}

interface UpdateStaffState {
  updateStaffList: UpdateStaffDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateStaffState = {
  updateStaffList: [],
  isLoading: false,
  error: null,
};

// Update Staff Async here //
export const updateStaffAsync = createAsyncThunk(
  "staff/update",
  async (
    { id, staffDetails }: { id: any; staffDetails: UpdateStaffDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/staff/update/${id}`,
        staffDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update staff slice here //
export const updateStaffSlice = createSlice({
  name: "updateStaffState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedStaff: (state) => {
      state.updateStaffList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateStaffAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateStaffAsync.fulfilled,
        (state, action: PayloadAction<UpdateStaffDetails>) => {
          state.isLoading = false;
          const index = state.updateStaffList.findIndex(
            (staff) => staff.id === action.payload.id
          );
          if (index !== -1) {
            state.updateStaffList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateStaffAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedStaff } = updateStaffSlice.actions;
export const updateStaffReducer = updateStaffSlice.reducer;
export const selectUpdateStaffDataList = (state: RootState) => {
  return state.updateStaffState?.updateStaffList ?? [];
};

//Delete staff Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteStaffState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteStaffState = {
  isLoading: false,
  error: null,
};

// Delete Staff Async here //

export const deleteStaffAsync = createAsyncThunk(
  "staff/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/staff/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Staff Slice here //

export const deleteStaffSlice = createSlice({
  name: "deleteStaffState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteStaff: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStaffAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteStaffAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteStaffAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteStaff } = deleteStaffSlice.actions;
export const deleteStaffReducer = deleteStaffSlice.reducer;

// handle staff status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateStaffStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateStaffStatusState {
  updateStaffStatusList: UpdateStaffStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateStaffStatusState = {
  updateStaffStatusList: [],
  isLoading: false,
  error: null,
};

// change Staff Status Async here //
export const updateStaffStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateStaffStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("staff/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/staff/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// // change Staff Status Slice here //

export const updateStaffStatusSlice = createSlice({
  name: "updateStaffStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedStaffStatus: (state) => {
      state.updateStaffStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateStaffStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateStaffStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateStaffStatusList.findIndex(
            (chapter) => chapter.id === action.payload.id
          );
          if (index !== -1) {
            state.updateStaffStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateStaffStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateStaffStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedStaffStatus } = updateStaffStatusSlice.actions;
export const updateStaffStatusReducer = updateStaffStatusSlice.reducer;
export const selectUpdateStaffStatusDataList = (state: RootState) => {
  return state.updateStaffStatusState?.updateStaffStatusList ?? [];
};

// upload staff //
interface UploadStaffDetails {
  file: File;
  additionalData: any;
}

// Upload Staff Async here //
export const uploadStaffAsync = createAsyncThunk(
  "staff/upload",
  async ({ file, additionalData }: UploadStaffDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const formData = new FormData();
    formData.append("file", file);
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/staff/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

interface UploadStaffState {
  isLoading: boolean;
  error: string | null;
}

const initialUploadState: UploadStaffState = {
  isLoading: false,
  error: null,
};

// Upload Staff Slice here //

export const uploadStaffSlice = createSlice({
  name: "uploadStaffState",
  initialState: initialUploadState,
  reducers: {
    clearUploadError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadStaffAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadStaffAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadStaffAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { clearUploadError } = uploadStaffSlice.actions;

export const selectUploadStaffError = (state: RootState) =>
  state.uploadStaffState.error;
export const selectUploadStaffLoading = (state: RootState) =>
  state.uploadStaffState.isLoading;
