import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Define the initial state for file upload //
interface FileUploadState {
  fileUrl: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialFileUploadState: FileUploadState = {
  fileUrl: null,
  isLoading: false,
  error: null,
};

// Upload file Async here //
export const uploadFileAsync = createAsyncThunk<
  { fileUrl: string },
  File,
  { rejectValue: { message: string } }
>("media/uploadFile", async (file, { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  const dbToken = localStorage.getItem("db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");
  const client_token = localStorage.getItem("client_token");
  const client_db_token = localStorage.getItem("client_db_token");

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(`${API_BASE_URL}/media/upload-file`, formData, {
      headers: {
        Authorization: `Bearer ${token || SToken || client_token}`,
        "Db-Token": dbToken || Sdb_token || client_db_token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { fileUrl: response?.data?.data?.imageUrl };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// Upload file Slice  here //
const fileUploadSlice = createSlice({
  name: "fileUploadState",
  initialState: initialFileUploadState,
  reducers: {
    clearFileUploadState: (state) => {
      state.fileUrl = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFileAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFileAsync.fulfilled, (state, action: PayloadAction<{ fileUrl: string }>) => {
        state.isLoading = false;
        state.fileUrl = action.payload.fileUrl;
      })
      .addCase(uploadFileAsync.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.payload?.message;
      });
  },
});

export const { clearFileUploadState } = fileUploadSlice.actions;
export const fileUploadReducer = fileUploadSlice.reducer;

// Selectors //
export const selectFileUrl = (state: RootState) => state.fileUploadState.fileUrl;
export const selectFileUploadError = (state: RootState) => state.fileUploadState.error;
export const selectFileUploadLoading = (state: RootState) => state.fileUploadState.isLoading;
