import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess, toastWarning } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Initial State
const initialState = {
  studentList: [],
  isLoading: false,
  error: null,
};

interface StudentDetails {
  reg_date: string;
  grade_id: string | number;
  section_id: string | number;
  gender: string;
  f_name: string;
  l_name: string;
  email: string;
  dob: string;
  phone: string;
  first_guardian_name: string;
  second_guardian_name: string;
  guardian_relation: string;
  guardian_phone: string;
  prv_school: string;
  remarks: string;
  session_id: number;
}

const getSessionId = () => {
  const session_id = localStorage.getItem("selected_session_id");
  const parsedSessionId = parseInt(session_id, 10);
  return isNaN(parsedSessionId) ? null : parsedSessionId;
};

// student create async here ///

export const createStudentAsync = createAsyncThunk(
  "student/create",
  async (credentials: StudentDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/student/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.user_found) {
        toastWarning(response?.data?.message);
        return rejectWithValue(response?.data);
      }

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// student create slice here ///
const studentSlice = createSlice({
  name: "studentState",
  initialState,
  reducers: {
    studentData: (state: any, action) => {
      state.studentList.push(action.payload);
    },
    resetStudentList: () => initialState,
    clearStudentError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createStudentAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.studentList = [...state.studentList, action.payload];
      })
      .addCase(createStudentAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const { resetStudentList, clearStudentError } = studentSlice.actions;
export default studentSlice.reducer;

export const selectStudentDataList = (state: RootState) =>
  state.studentState?.studentList ?? [];

// Student List Slice

interface ErrorPayload {
  message: string;
  status: number;
}

interface StudentListDetails {
  id: string;
  f_name: string;
  l_name: string;
  email: string;
  dob: string;
  phone: string;
  first_guardian_name: string;
  second_guardian_name: string;
  guardian_relation: string;
  guardian_phone: string;
  prv_school: string;
  remarks: string;
  grades: string[];
  status: boolean;
}

interface StudentListState {
  studentDataList: StudentListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

const studentListInitialState: StudentListState = {
  studentDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

interface StudentListResponse {
  students: StudentListDetails[];
  count: number;
}

interface FetchStudentsArgs {
  gradeId?: number[];
  fNameIds?: string[];
  studentId?: string[];
  statusId?: boolean;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  session_id?: number;
  isAllDataFetch?: boolean;
}

// student list async here //

export const StudentListAsync = createAsyncThunk<
  StudentListResponse,
  FetchStudentsArgs,
  { rejectValue: ErrorPayload }
>(
  "student/create/list",
  async (
    {
      gradeId,
      fNameIds,
      studentId,
      statusId,
      searchQuery,
      page = 1,
      pageSize = 10,
      session_id,
      isAllDataFetch,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    // const session_id = getSessionId();

    try {
      const filter: any = { session_id };
      if (gradeId) filter.grade_id = gradeId;
      if (fNameIds && fNameIds.length > 0) filter.f_name = fNameIds;
      if (studentId && studentId.length > 0) filter.id = studentId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.f_name = searchQuery;

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const response = await axios.post(
        `${API_BASE_URL}/student/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const students = response?.data?.data as StudentListDetails[];
        return { students, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// student list slice here //
export const studentListSlice = createSlice({
  name: "studentListState",
  initialState: studentListInitialState,
  reducers: {
    clearStudentList: () => studentListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(StudentListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        StudentListAsync.fulfilled,
        (state, action: PayloadAction<StudentListResponse>) => {
          state.isLoading = false;
          state.studentDataList = action.payload.students;
          state.count = action.payload.count;
        }
      )
      .addCase(StudentListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearStudentList } = studentListSlice.actions;
export const studentListReducer = studentListSlice.reducer;

export const DisplayStudentDataList = (state: RootState) =>
  state.studentListState.studentDataList ?? [];
export const selectStudentListCount = (state: RootState) =>
  state.studentListState.count;

// Update Student Slice

interface UpdateStudentDetails {
  [x: string]: any;
  reg_date: string;
  grade_id: string | number;
  section_id: string | number;
  gender: string;
  f_name: string;
  l_name: string;
  dob: string;
  phone: string;
  first_guardian_name: string;
  second_guardian_name: string;
  guardian_relation: string;
  guardian_phone: string;
  prv_school: string;
  remarks: string;
  session_id: number;
}

interface UpdateStudentState {
  updateStudentList: UpdateStudentDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateStudentState = {
  updateStudentList: [],
  isLoading: false,
  error: null,
};

// update students async here //

export const updateStudentAsync = createAsyncThunk(
  "student/update",
  async (
    { id, studentDetails }: { id: any; studentDetails: UpdateStudentDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    // const session_id = getSessionId();

    try {
      const response = await axios.post(
        `${API_BASE_URL}/student/update/${id}`,
        studentDetails, // Include session_id in the payload
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update students slice here //
export const updateStudentSlice = createSlice({
  name: "updateStudentState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedStudent: (state) => {
      state.updateStudentList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateStudentAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateStudentAsync.fulfilled,
        (state, action: PayloadAction<UpdateStudentDetails>) => {
          state.isLoading = false;
          const index = state.updateStudentList.findIndex(
            (student) => student.id === action.payload.id
          );
          if (index !== -1) {
            state.updateStudentList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateStudentAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedStudent } = updateStudentSlice.actions;
export const updateStudentReducer = updateStudentSlice.reducer;
export const selectUpdateStudentDataList = (state: RootState) => {
  return state.updateStudentState?.updateStudentList ?? [];
};

// Delete Student Slice

interface DeleteStudentState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteStudentState = {
  isLoading: false,
  error: null,
};

// delete students async here //

export const deleteStudentAsync = createAsyncThunk(
  "student/delete",
  async (
    { id, session_id }: { id: string; session_id: number },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    // const session_id = getSessionId();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/student/delete/${id}`,
        { session_id }, // Include session_id in the payload
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// delete students slice here //
export const deleteStudentSlice = createSlice({
  name: "deleteStudentState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteStudent: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStudentAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteStudentAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteStudentAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearDeleteStudent } = deleteStudentSlice.actions;
export const deleteStudentReducer = deleteStudentSlice.reducer;

// Update Student Status Slice

interface UpdateStudentStatusDetails {
  id: number;
  currentStatus: boolean;
}

interface UpdateStudentStatusState {
  updateStudentStatusList: UpdateStudentStatusDetails[];
  isLoading: boolean;
  error: null;
}

const initialUpdatedStatusState: UpdateStudentStatusState = {
  updateStudentStatusList: [],
  isLoading: false,
  error: null,
};

// update students async here //

export const updateStudentStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  { id: number; session_id: number },
  {
    state: RootState;
    rejectValue: ErrorPayload;
  }
>("student/update/status", async ({ id, session_id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");
  // const session_id = getSessionId();
  try {
    const response = await axios.post(
      `${API_BASE_URL}/student/change-status/${id}`,
      { session_id }, // Include session_id in the payload
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// update students slice here //
export const updateStudentStatusSlice = createSlice({
  name: "updateStudentStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedStudentStatus: (state) => {
      state.updateStudentStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateStudentStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateStudentStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateStudentStatusList.findIndex(
            (student) => student.id === action.payload.id
          );
          if (index !== -1) {
            state.updateStudentStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateStudentStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateStudentStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedStudentStatus } = updateStudentStatusSlice.actions;
export const updateStatusReducer = updateStudentStatusSlice.reducer;
export const selectUpdateStudentStatusDataList = (state: RootState) => {
  return state.updateStudentStatusState?.updateStudentStatusList ?? [];
};

// Upload Students Slice

interface UploadStudentsDetails {
  file: File;
  additionalData: any;
}

// upload students file async here //
export const uploadStudentsAsync = createAsyncThunk(
  "students/upload",
  async (
    { file, additionalData }: UploadStudentsDetails,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const formData = new FormData();
    formData.append("file", file);

    // Append session_id and other additional data if available
    if (additionalData.session_id) {
      formData.append("session_id", additionalData.session_id);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/student/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// upload student slice here //
interface UploadStudentState {
  isLoading: boolean;
  error: string | null;
}

const initialUploadState: UploadStudentState = {
  isLoading: false,
  error: null,
};

export const uploadStudentSlice = createSlice({
  name: "uploadStudentState",
  initialState: initialUploadState,
  reducers: {
    clearUploadError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadStudentsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadStudentsAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadStudentsAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const { clearUploadError } = uploadStudentSlice.actions;

export const selectUploadStudentError = (state: RootState) =>
  state.uploadStudentState.error;
export const selectUploadStudentLoading = (state: RootState) =>
  state.uploadStudentState.isLoading;
