import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { RootState } from "redux/store";
import { toastError, toastSuccess } from "helpers/toastHelper";

// Define the initial state interface
interface ModulePermission {
  action: string;
  status: boolean;
  api_path: string;
}

export interface Module {
  module_id: number;
  module: string;
  module_permissions: ModulePermission[];
}

export interface RoleData {
  id: number;
  role_id: number;
  role: string;
  modules: Module[];
}

interface ActiveModule {
  module_id: number;
  module: string;
}

interface ModuleWiseAccessState {
  data: RoleData[];
  activeModules: ActiveModule[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Initial state //
const initialState: ModuleWiseAccessState = {
  data: [],
  activeModules: [],
  status: "idle",
  error: null,
};

//  modules wise Access Permission Async here //
export const fetchActiveModulesAsync = createAsyncThunk<
  ActiveModule[],
  void,
  { rejectValue: { message: string; status: number } }
>("moduleWiseAccessList/fetchActiveModules", async (_, { rejectWithValue }) => {
  const token =
    localStorage.getItem("client_token") || localStorage.getItem("token");
  const dbToken =
    localStorage.getItem("client_db_token") || localStorage.getItem("db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");
  try {
    const response = await axios.post(
      `${API_BASE_URL}/permission/active-modules`,
      {},
      {
        headers: {
          Authorization: `Bearer ${SToken || token}`,
          "Db-Token": Sdb_token || dbToken,
        },
      }
    );
    return response?.data?.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({
      message: errorMessage,
      status: error.response?.status,
    });
  }
});

// module-wise access list Async here //
export const fetchModuleWiseAccessListAsync = createAsyncThunk<
  RoleData[],
  void,
  { rejectValue: { message: string; status: number } }
>(
  "moduleWiseAccessList/fetchModuleWiseAccessList",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const user_token = localStorage.getItem("token");
    const user_db_token = localStorage.getItem("db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/permission/list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken || user_token}`,
            "Db-Token": db_token || Sdb_token || user_db_token,
          },
        }
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);


// module-wise change permission Async here //
export const changePermissionAsync = createAsyncThunk<
  { id: number; module_id: number; action: string; status: boolean },
  { id: number; module_id: number; action: string },
  { rejectValue: { message: string; status: number } }
>(
  "moduleWiseAccessList/changePermission",
  async ({ id, module_id, action }, { rejectWithValue }) => {

    const token =
      localStorage.getItem("client_token") || localStorage.getItem("token");
    const dbToken =
      localStorage.getItem("client_db_token") ||
      localStorage.getItem("db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/permission/change-permission/${id}`,
        { module_id, action },
        {
          headers: {
            Authorization: `Bearer ${SToken || token}`,
            "Db-Token": Sdb_token || dbToken,
          },
        }
      );

      const newStatus = response.data.data?.[0] === 1;
      toastSuccess(response?.data?.message || "permission changed succesfully")
      return { id, module_id, action, status: newStatus };
    } catch (error: any) {
      return rejectWithValue({ message: error.message, status: 500 });
    }
  }
);

// Module - wise -Access permission Slcie here //
export const ModuleWiseAccessSlice = createSlice({
  name: "moduleWiseAccessListState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModuleWiseAccessListAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchModuleWiseAccessListAsync.fulfilled,
        (state, action: PayloadAction<RoleData[]>) => {
          state.status = "succeeded";
          state.data = action.payload;
        }
      )
      .addCase(
        fetchModuleWiseAccessListAsync.rejected,
        (
          state,
          action: PayloadAction<{ message: string; status: number } | undefined>
        ) => {
          state.status = "failed";
          state.error =
            action.payload?.message ||
            "Failed to fetch module-wise access list";
        }
      )
      .addCase(fetchActiveModulesAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchActiveModulesAsync.fulfilled,
        (state, action: PayloadAction<ActiveModule[]>) => {
          state.status = "succeeded";
          state.activeModules = action.payload;
        }
      )
      .addCase(
        fetchActiveModulesAsync.rejected,
        (
          state,
          action: PayloadAction<{ message: string; status: number } | undefined>
        ) => {
          state.status = "failed";
          state.error =
            action.payload?.message || "Failed to fetch active modules";
        }
      )
      .addCase(
        changePermissionAsync.fulfilled,
        (
          state,
          action: PayloadAction<{
            id: number;
            module_id: number;
            action: string;
            status: boolean;
          }>
        ) => {
          const { id, module_id, action: permissionAction, status } = action.payload;
          const role = state.data.find((role) => role.id === id);
          if (role) {
            const module = role.modules.find((m) => m.module_id === module_id);
            if (module) {
              const permission = module.module_permissions.find(
                (perm) => perm.action === permissionAction
              );
              if (permission) {
                permission.status = status;
              }
            }
          }
        }
      );
  },
});

export default ModuleWiseAccessSlice.reducer;

// Selectors //
export const selectModuleAccessData = (state: RootState) =>
  state.moduleWiseAccessListState.data;
export const selectActiveModules = (state: RootState) =>
  state.moduleWiseAccessListState.activeModules;
export const selectModuleAccessListStatus = (state: RootState) =>
  state.moduleWiseAccessListState.status;
export const selectModuleAccessListError = (state: RootState) =>
  state.moduleWiseAccessListState.error;
