// redux/features/examMarkingSlice.ts

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

interface ExamMarkDetails {
  id: number;
  marks: number;
  student_id: number;
  exam_id: number;
  sectionId?: number[];
}

interface UpdateExamMarkData {
  question_data: { question_id: number; marks: number }[];
  obtained_marks: number;
}

interface ExamMarkState {
  examMarkList: ExamMarkDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ExamMarkState = {
  examMarkList: [],
  isLoading: false,
  error: null,
};

// List Exam Marks Async here //

export const listExamMarksAsync = createAsyncThunk(
  "examMarks/list",
  async (
    {
      searchQuery,
      examId,
      page,
      pageSize,
      isAllDataFetch,
      sort,
      sectionId,
    }: {
      searchQuery?: string,
      examId: number;
      page?: number;
      pageSize?: number;
      sectionId?: number[];
      isAllDataFetch?: boolean;
      sort?: { field: string; order: string };
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = {
        exam_id: examId,
      };

      if (searchQuery) filter.search = searchQuery;
      // **Only add section_id if filtering is applied**
      if (sectionId && sectionId.length > 0) {
        filter.section_id = sectionId;
      }

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const response = await axios.post(
        `${API_BASE_URL}/exam-marks/list`,
        {
          filter,
          sort,
          range: { page, pageSize },
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return {
          examMarks: response?.data?.data,
          count: response?.data?.count,
        };
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);


// Update Exam Marks Async here  //
export const updateExamMarksAsync = createAsyncThunk(
  "examMarks/update",
  async (
    { Id, updateData }: { Id: number; updateData: UpdateExamMarkData },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam-marks/update/${Id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update Exam Marks Slice here  //

const examMarkingSlice = createSlice({
  name: "examMarkingState",
  initialState,
  reducers: {
    clearExamMarkingError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listExamMarksAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listExamMarksAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.examMarkList = action.payload.examMarks;
      })
      .addCase(listExamMarksAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(updateExamMarksAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateExamMarksAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateExamMarksAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors //
export const { clearExamMarkingError } = examMarkingSlice.actions;
export const examMarkingReducer = examMarkingSlice.reducer;

export const selectExamMarkList = (state: RootState) =>
  state.examMarkingState.examMarkList;
export const selectExamMarkError = (state: RootState) =>
  state.examMarkingState.error;
export const selectExamMarkLoading = (state: RootState) =>
  state.examMarkingState.isLoading;

export default examMarkingSlice;

// update student attendance here //
interface ErrorPayload {
  message: string;
}
interface UpdateStudentAttendanceDetails {
  attendanceStatus: string;
  examStudentMarkId: number[];
}

interface UpdateStudentAttendanceState {
  updateStudentAttendanceList: UpdateStudentAttendanceDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateStudentAttendanceState = {
  updateStudentAttendanceList: [],
  isLoading: false,
  error: null,
};

// update student Attendance Async here ///

export const updateStudentAttendanceAsync = createAsyncThunk(
  "student/update/attendance",
  async (
    {
      studentAttendanceDetails,
    }: { studentAttendanceDetails: UpdateStudentAttendanceDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam-marks/mark-attendance`,
        studentAttendanceDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      return rejectWithValue({
        message: error.response?.data?.message,
        status: error.response?.status,
      });
    }
  }
);

// update student attendance slice //
export const updateStudentAttendanceSlice = createSlice({
  name: "updateStudentAttendanceState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedStudentAttendance: (state) => {
      state.updateStudentAttendanceList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateStudentAttendanceAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateStudentAttendanceAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.updateStudentAttendanceList = [
          ...state.updateStudentAttendanceList,
          action.payload,
        ];
      })
      .addCase(
        updateStudentAttendanceAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedStudentAttendance } =
  updateStudentAttendanceSlice.actions;
export const updateStudentAttendanceReducer =
  updateStudentAttendanceSlice.reducer;
export const selectUpdateStudentAttendanceDataList = (state: RootState) => {
  return state.updateStudentAttendanceState?.updateStudentAttendanceList ?? [];
};
