import { lazy } from "react";
import { Loadable, MainLayout, MinimalLayout } from "components";
import ViewQuestionPaperComponent from "pages/school-admin/question-papers/view-question-paper";
import ClassesComponent from "pages/school-admin/classes";
const PdfDownloadComponent = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-download"))
);

const TeacherReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/teacher-report"))
);
const ClientPanelTeacherReport = Loadable(
  lazy(() => import("pages/school-admin/view-reports/teacher-report"))
);

const SchoolReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/school-report"))
);
const ClinetSchoolReport = Loadable(
  lazy(() => import("pages/school-admin/view-reports/school-report"))
);
const Error404 = Loadable(lazy(() => import("pages/auth/error")));
const SchoolDashBoardComponent = Loadable(
  lazy(() => import("pages/school-admin/dashboard"))
);
const SuperDashBoardComponent = Loadable(
  lazy(() => import("pages/super-admin/dashboard"))
);
const QuestionClass = Loadable(
  lazy(() => import("pages/school-admin/classes"))
);
const QuestionSection = Loadable(
  lazy(() => import("pages/school-admin/sections"))
);
const QuestionSubject = Loadable(
  lazy(() => import("pages/school-admin/subjects"))
);
const QuestionChapter = Loadable(
  lazy(() => import("pages/school-admin/chapters"))
);
const QuestionTopic = Loadable(lazy(() => import("pages/school-admin/topics")));
const Question = Loadable(lazy(() => import("pages/school-admin/questions")));
const TeachersComponent = Loadable(
  lazy(() => import("pages/school-admin/staffs"))
);
const StudentsComponent = Loadable(
  lazy(() => import("pages/school-admin/students"))
);
const QuestionPapers = Loadable(
  lazy(() => import("pages/school-admin/question-papers"))
);
const CreateManualQuestionPapers = Loadable(
  lazy(
    () =>
      import("pages/school-admin/question-papers/manual/create-question-paper")
  )
);
const CreateAutomaticQuestionPapers = Loadable(
  lazy(
    () =>
      import(
        "pages/school-admin/question-papers/automatic/create-question-paper"
      )
  )
);
const StudentExamReport = Loadable(
  lazy(() => import("pages/student-admin/view-reports/student-report"))
);
const CreateQuestions = Loadable(
  lazy(() => import("pages/school-admin/create-questions"))
);
const StudentReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/student-report"))
);
const ClientStudentReport = Loadable(
  lazy(() => import("pages/school-admin/view-reports/student-report"))
);
const ManageProfileComponent = Loadable(
  lazy(() => import("pages/student-admin/manage-profile"))
);
const PreviewAutomatic = Loadable(
  lazy(() => import("pages/school-admin/question-papers/automatic/preview"))
);
const ExamComponents = Loadable(lazy(() => import("pages/school-admin/exam")));
const ExamMarkingComponents = Loadable(
  lazy(() => import("pages/school-admin/exam-marking"))
);
const StudentExamComponent = Loadable(lazy(() => import("pages/student-admin/exam")));
const StudentExamMarkingComponent = Loadable(
  lazy(() => import("pages/student-admin/exam-marking"))
);


const SessionComponent = Loadable(
  lazy(() => import("pages/super-admin/sessions"))
);
const SchoolComponent = Loadable(
  lazy(() => import("pages/super-admin/schools"))
);
const SchoolAdminsComponent = Loadable(
  lazy(() => import("pages/super-admin/school-admins"))
);
const RolesComponent = Loadable(lazy(() => import("pages/super-admin/roles")));


const ManageInstructionsComponent = Loadable(
  lazy(() => import("pages/school-admin/manage-instructions"))
);

const ModuleWiseAccessComponent = Loadable(
  lazy(() => import("pages/super-admin/module-wise-access"))
);

const ModuleWiseAccessComponents = Loadable(
  lazy(() => import("pages/school-admin/module-wise-access"))
);

const PdfPreview = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-preview"))
);

const token = localStorage.getItem("token");
const SToken = localStorage.getItem("S_token");

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/super-admin/dashboard",
      element: <SuperDashBoardComponent />,
    },
    ...(SToken
      ? [
        {
          path: "/",
          element: <ClassesComponent />,
        },
      ]
      : token
        ? [
          {
            path: "/",
            element: <SessionComponent />,
          },
        ]
        : []),
    {
      path: "/school-admin/dashboard",
      element: <SchoolDashBoardComponent />,
    },
    {
      path: "/school-admin/classes",
      element: <QuestionClass />,
    },
    {
      path: "/school-admin/sections",
      element: <QuestionSection />,
    },
    {
      path: "/school-admin/subjects",
      element: <QuestionSubject />,
    },
    {
      path: "/school-admin/chapters",
      element: <QuestionChapter />,
    },
    {
      path: "/school-admin/topics",
      element: <QuestionTopic />,
    },
    {
      path: "/school-admin/staffs",
      element: <TeachersComponent />,
    },
    {
      path: "/school-admin/students",
      element: <StudentsComponent />,
    },
    {
      path: "/school-admin/questions",
      element: <Question />,
    },
    {
      path: "/school-admin/questions/create",
      element: <CreateQuestions />,
    },
    {
      path: "/school-admin/question-papers",
      element: <QuestionPapers />,
    },
    {
      path: "/school-admin/exam",
      element: <ExamComponents />,
    },
    {
      path: "/school-admin/exam/exam-marking",
      element: <ExamMarkingComponents />,
    },
    {
      path: "/school-admin/question-papers/manual/create",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/manual/create/:id",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/automatic/create",
      element: <CreateAutomaticQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/automatic/preview",
      element: <PreviewAutomatic />,
    },
    {
      path: "/school-admin/manage-profile",
      element: <ManageProfileComponent />,
    },
    {
      path: "/school-admin/question-papers/preview/:id",
      element: <ViewQuestionPaperComponent setFinalPageCount={undefined} />,
    },
    {
      path: "/school-admin/question-papers/pdf/:id",
      element: <PdfDownloadComponent />,
    },
    {
      path: "/school-admin/question-papers/pdf-preview/:id",
      element: <PdfPreview />,
    },
    {
      path: "/school-admin/manage-instruction",
      element: <ManageInstructionsComponent instructionList={undefined} />,
    },
    {
      path: "/school-admin/module-wise-access",
      element: <ModuleWiseAccessComponents />,
    },
    {
      path: "/school-admin/view-reports/school-reports",
      element: <ClinetSchoolReport />,
    },
    {
      path: "/school-admin/view-reports/teacher-report",
      element: <ClientPanelTeacherReport />,
    },
    {
      path: "/school-admin/view-reports/student-reports",
      element: <ClientStudentReport />,
    },

    // super admin route starts from here //

    {
      path: "/super-admin/sessions",
      element: <SessionComponent />,
    },
    {
      path: "/super-admin/schools",
      element: <SchoolComponent />,
    },
    {
      path: "/super-admin/school-admins",
      element: <SchoolAdminsComponent />,
    },
    {
      path: "/super-admin/roles",
      element: <RolesComponent />,
    },
    {
      path: "/super-admin/module-wise-access",
      element: <ModuleWiseAccessComponent />,
    },
    {
      path: "/super-admin/module-wise-access/:roleId",
      element: <ModuleWiseAccessComponent />,
    },
    {
      path: "/super-admin/view-reports/school-reports",
      element: <SchoolReport />,
    },
    {
      path: "/super-admin/view-reports/teacher-report",
      element: <TeacherReport />,
    },
    {
      path: "/super-admin/view-reports/student-reports",
      element: <StudentReport />,
    },

    // student admin route starts from here //
    {
      path: "/student-admin/exam",
      element: <StudentExamComponent />,
    },
    {
      path: "/student-admin/exam/exam-marking",
      element: <StudentExamMarkingComponent />,
    },
    {
      path: "/student-admin/view-reports/student-reports",
      element: <StudentExamReport />,
    },
    {
      path: "/student-admin/view-reports/school-reports",
      element: <ClinetSchoolReport />,
    },
    {
      path: "/student-admin/view-reports/teacher-report",
      element: <ClientPanelTeacherReport />,
    },
    {
      path: "/student-admin/dashboard",
      element: <SchoolDashBoardComponent />,
    },
    {
      path: "/student-admin/classes",
      element: <QuestionClass />,
    },
    {
      path: "/student-admin/sections",
      element: <QuestionSection />,
    },
    {
      path: "/student-admin/subjects",
      element: <QuestionSubject />,
    },
    {
      path: "/student-admin/chapters",
      element: <QuestionChapter />,
    },
    {
      path: "/student-admin/topics",
      element: <QuestionTopic />,
    },
    {
      path: "/student-admin/staffs",
      element: <TeachersComponent />,
    },
    {
      path: "/student-admin/students",
      element: <StudentsComponent />,
    },
    {
      path: "/student-admin/questions",
      element: <Question />,
    },
    {
      path: "/student-admin/questions/create",
      element: <CreateQuestions />,
    },
    {
      path: "/student-admin/question-papers",
      element: <QuestionPapers />,
    },
    {
      path: "/student-admin/question-papers/manual/create",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/student-admin/question-papers/manual/create/:id",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/student-admin/question-papers/automatic/create",
      element: <CreateAutomaticQuestionPapers />,
    },
    {
      path: "/student-admin/question-papers/automatic/preview",
      element: <PreviewAutomatic />,
    },
    {
      path: "/student-admin/manage-profile",
      element: <ManageProfileComponent />,
    },
    {
      path: "/student-admin/question-papers/preview/:id",
      element: <ViewQuestionPaperComponent setFinalPageCount={undefined} />,
    },
    {
      path: "/student-admin/question-papers/pdf/:id",
      element: <PdfDownloadComponent />,
    },
    {
      path: "/student-admin/question-papers/pdf-preview/:id",
      element: <PdfPreview />,
    },
    {
      path: "/student-admin/manage-instruction",
      element: <ManageInstructionsComponent instructionList={undefined} />,
    },
    {
      path: "/student-admin/module-wise-access",
      element: <ModuleWiseAccessComponents />,
    },


    // Staff admin routes starts from here //
    {
      path: "/staff-admin/dashboard",
      element: <SchoolDashBoardComponent />,
    },
    {
      path: "/staff-admin/classes",
      element: <QuestionClass />,
    },
    {
      path: "/staff-admin/sections",
      element: <QuestionSection />,
    },
    {
      path: "/staff-admin/subjects",
      element: <QuestionSubject />,
    },
    {
      path: "/staff-admin/chapters",
      element: <QuestionChapter />,
    },
    {
      path: "/staff-admin/topics",
      element: <QuestionTopic />,
    },
    {
      path: "/staff-admin/staffs",
      element: <TeachersComponent />,
    },
    {
      path: "/staff-admin/students",
      element: <StudentsComponent />,
    },
    {
      path: "/staff-admin/questions",
      element: <Question />,
    },
    {
      path: "/staff-admin/questions/create",
      element: <CreateQuestions />,
    },
    {
      path: "/staff-admin/question-papers",
      element: <QuestionPapers />,
    },
    {
      path: "/staff-admin/exam",
      element: <ExamComponents />,
    },
    {
      path: "/staff-admin/exam/exam-marking",
      element: <ExamMarkingComponents />,
    },
    {
      path: "/staff-admin/question-papers/manual/create",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/staff-admin/question-papers/manual/create/:id",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/staff-admin/question-papers/automatic/create",
      element: <CreateAutomaticQuestionPapers />,
    },
    {
      path: "/staff-admin/question-papers/automatic/preview",
      element: <PreviewAutomatic />,
    },
    {
      path: "/staff-admin/manage-profile",
      element: <ManageProfileComponent />,
    },
    {
      path: "/staff-admin/question-papers/preview/:id",
      element: <ViewQuestionPaperComponent setFinalPageCount={undefined} />,
    },
    {
      path: "/staff-admin/question-papers/pdf/:id",
      element: <PdfDownloadComponent />,
    },
    {
      path: "/staff-admin/question-papers/pdf-preview/:id",
      element: <PdfPreview />,
    },
    {
      path: "/staff-admin/manage-instruction",
      element: <ManageInstructionsComponent instructionList={undefined} />,
    },
    {
      path: "/staff-admin/module-wise-access",
      element: <ModuleWiseAccessComponents />,
    },
    {
      path: "/staff-admin/view-reports/school-reports",
      element: <ClinetSchoolReport />,
    },
    {
      path: "/staff-admin/view-reports/teacher-report",
      element: <ClientPanelTeacherReport />,
    },
    {
      path: "/staff-admin/view-reports/student-reports",
      element: <ClientStudentReport />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ],
};

export default MainRoutes;
