import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import { getUser } from 'helpers/storage';

export default function ThemeRoutes() {
  const user = getUser()
  
  return useRoutes((user ?? "").trim() !== "" ? [MainRoutes] : [LoginRoutes]);
}