import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Login As super-admin functionality for student reports//
interface ErrorPayload {
  message: string;
  status: number;
}

const initialLoginState = {
  schoolAdminLoginList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface SchoolAdminLoginDetails {
  client_id: string;
  email: string;
}

// Login -as School Admin Async here //
export const schoolAdminLoginForReportsAsync = createAsyncThunk<
  any,
  SchoolAdminLoginDetails,
  { rejectValue: ErrorPayload }
>(
  "school/admin/login",
  async (credentials: SchoolAdminLoginDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/login-as`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        localStorage.setItem("reportToken", response?.data?.access_token);
        localStorage.setItem("reportdb_token", response?.data?.db_token);

        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Login -as School Admin Slice here //
export const schoolAdminLoginForReportsSlice = createSlice({
  name: "schoolAdminLoginReportsState",
  initialState: initialLoginState,
  reducers: {
    schoolAdminLoginData: (state, action: PayloadAction<any>) => {
      state.schoolAdminLoginList.push(action.payload);
    },
    resetSchoolAdminLoginList: () => initialLoginState,
    clearSchoolAdminLoginError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(schoolAdminLoginForReportsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        schoolAdminLoginForReportsAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.schoolAdminLoginList.push(action.payload);
        }
      )
      .addCase(schoolAdminLoginForReportsAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetSchoolAdminLoginList, clearSchoolAdminLoginError } =
  schoolAdminLoginForReportsSlice.actions;

export const selectSchoolAdminLoginDataList = (state: RootState) =>
  state.schoolAdminLoginReportsState?.schoolAdminLoginList ?? [];

// Overall performance reports//

const initialState = {
  schoolOverallPerformanceList: [],
  isLoading: false,
  error: null,
};


// School Over all performance Details here //
interface SchoolOverallPerformanceDetails {
  filter: {
    session_id: number | null;
    grade_id: number | null;
    section_id: number[] | null;
    subject_id: number | null;
    type?: string | null;
  };
}

// Create School Async  here //
export const createSchoolOverallPerformanceAsync = createAsyncThunk(
  "school/overall/performance",
  async (credentials: SchoolOverallPerformanceDetails, { rejectWithValue }) => {

    try {

      let tokenHeaders = {};

      // Retrieve tokens from localStorage
      const reportToken = localStorage.getItem("reportToken");
      const reportdb_token = localStorage.getItem("reportdb_token");
      const SToken = localStorage.getItem("S_token");
      const Sdb_token = localStorage.getItem("S_db_token");
      const clientToken = localStorage.getItem("client_token");
      const clientDbToken = localStorage.getItem("client_db_token");

      if (reportToken && reportdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${reportToken}`,
          "Db-Token": reportdb_token,
        };
      } else if (SToken && Sdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${SToken}`,
          "Db-Token": Sdb_token,
        };
      } else if (clientToken && clientDbToken) {
        tokenHeaders = {
          Authorization: `Bearer ${clientToken}`,
          "Db-Token": clientDbToken,
        };
      } else {
        throw new Error("No valid authentication tokens found.");
      }
      const response = await axios.post(
        `${API_BASE_URL}/report/subject-performance`,
        { filter: credentials.filter },
        {
          headers: tokenHeaders,
        },
      );

      if (response?.data?.success) {
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create School overall performance Slice here //

export const schoolOverallPerformanceSlice = createSlice({
  name: "schoolOverallPerformanceState",
  initialState,
  reducers: {
    schoolOverallPerfomanceData: (state: any, action) => {
      state.schoolOverallPerformanceList.push(action.payload);
    },
    resetSchoolOverallPerformanceList: () => initialState,
    clearSchoolOverallPerformanceError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolOverallPerformanceAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        createSchoolOverallPerformanceAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = false;
          state.schoolOverallPerformanceList = [
            ...state.schoolOverallPerformanceList,
            action.payload,
          ];
        }
      )
      .addCase(
        createSchoolOverallPerformanceAsync.rejected,
        (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );
  },
});

// Selectors here //
export const {
  resetSchoolOverallPerformanceList,
  clearSchoolOverallPerformanceError,
} = schoolOverallPerformanceSlice.actions;
export default schoolOverallPerformanceSlice.reducer;

export const selectSchoolOverallPerformanceDataList = (state: RootState) =>
  state.schoolOverallPerformanceState?.schoolOverallPerformanceList ?? [];

// table showing based on particular exam id //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialExamTableDataState = {
  examTableDataList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface ExamTableDetails {
  exam_id: string;
}

// student performance Async here //
export const examTableDataAsync = createAsyncThunk<
  any,
  ExamTableDetails,
  { rejectValue: ErrorPayload }
>(
  "exam/table/data",
  async (credentials: ExamTableDetails, { rejectWithValue }) => {
    try {
      let tokenHeaders = {};

      // Retrieve tokens from localStorage
      const reportToken = localStorage.getItem("reportToken");
      const reportdb_token = localStorage.getItem("reportdb_token");
      const SToken = localStorage.getItem("S_token");
      const Sdb_token = localStorage.getItem("S_db_token");
      const clientToken = localStorage.getItem("client_token");
      const clientDbToken = localStorage.getItem("client_db_token");

      if (reportToken && reportdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${reportToken}`,
          "Db-Token": reportdb_token,
        };
      } else if (SToken && Sdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${SToken}`,
          "Db-Token": Sdb_token,
        };
      } else if (clientToken && clientDbToken) {
        tokenHeaders = {
          Authorization: `Bearer ${clientToken}`,
          "Db-Token": clientDbToken,
        };
      } else {
        throw new Error("No valid authentication tokens found.");
      }
      const response = await axios.post(
        `${API_BASE_URL}/report/student-performance`,
        credentials,
        {
          headers: tokenHeaders,
        },
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Exam table Data Slice here //

export const examTableDataSlice = createSlice({
  name: "examTableDataState",
  initialState: initialExamTableDataState,
  reducers: {
    resetExamTableDataList: (state) => {
      state.examTableDataList = [];
    },
    clearExamTableDataError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(examTableDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        examTableDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.examTableDataList = action.payload;
        }
      )
      .addCase(examTableDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetExamTableDataList, clearExamTableDataError } =
  examTableDataSlice.actions;

export const selectExamTableDataDataList = (state: RootState) =>
  state.examTableDataState?.examTableDataList ?? [];

// chapter wise data showing based on particular exam id //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialChapterWiseDataState = {
  chapterWiseDataList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface ChapterWiseDataDetails {
  exam_id: string;
}

// chapter wise data Async here //
export const chapterWiseDataAsync = createAsyncThunk<
  any,
  ChapterWiseDataDetails,
  { rejectValue: ErrorPayload }
>(
  "chapter/data",
  async (credentials: ChapterWiseDataDetails, { rejectWithValue }) => {
    try {
      let tokenHeaders = {};

      // Retrieve tokens from localStorage
      const reportToken = localStorage.getItem("reportToken");
      const reportdb_token = localStorage.getItem("reportdb_token");
      const SToken = localStorage.getItem("S_token");
      const Sdb_token = localStorage.getItem("S_db_token");
      const clientToken = localStorage.getItem("client_token");
      const clientDbToken = localStorage.getItem("client_db_token");

      if (reportToken && reportdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${reportToken}`,
          "Db-Token": reportdb_token,
        };
      } else if (SToken && Sdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${SToken}`,
          "Db-Token": Sdb_token,
        };
      } else if (clientToken && clientDbToken) {
        tokenHeaders = {
          Authorization: `Bearer ${clientToken}`,
          "Db-Token": clientDbToken,
        };
      } else {
        throw new Error("No valid authentication tokens found.");
      }
      const response = await axios.post(
        `${API_BASE_URL}/report/chapter-performance`,
        credentials,
        {
          headers: tokenHeaders,
        },
      );

      if (response?.data?.success) {
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Chapter Wise Data Slice here //

export const chapterWiseDataSlice = createSlice({
  name: "chapterWiseDataState",
  initialState: initialChapterWiseDataState,
  reducers: {
    resetChapterWiseDataList: (state) => {
      state.chapterWiseDataList = [];
    },
    clearChapterWiseDataError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chapterWiseDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        chapterWiseDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          if (action.payload && action.payload.chapterWisePerformance) {
            state.chapterWiseDataList = action.payload.chapterWisePerformance;
          } else {
            state.chapterWiseDataList = [];
          }
        }
      )

      .addCase(chapterWiseDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetChapterWiseDataList, clearChapterWiseDataError } =
  chapterWiseDataSlice.actions;

export const selectChapterWiseDataList = (state: RootState) =>
  state.chapterWiseDataState?.chapterWiseDataList ?? [];

// grades system for student report //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialGradesState = {
  gradesList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface GradesDetails {
  filter: {
    grade_id: number;
  };
}

// AsyncThunk for fetching grades for the pie chart //
export const gradesAsync = createAsyncThunk<
  any,
  GradesDetails,
  { rejectValue: ErrorPayload }
>("grades/reports", async (credentials: GradesDetails, { rejectWithValue }) => {

  try {

    let tokenHeaders = {};

    // Retrieve tokens from localStorage
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const clientToken = localStorage.getItem("client_token");
    const clientDbToken = localStorage.getItem("client_db_token");

    if (reportToken && reportdb_token) {
      tokenHeaders = {
        Authorization: `Bearer ${reportToken}`,
        "Db-Token": reportdb_token,
      };
    } else if (SToken && Sdb_token) {
      tokenHeaders = {
        Authorization: `Bearer ${SToken}`,
        "Db-Token": Sdb_token,
      };
    } else if (clientToken && clientDbToken) {
      tokenHeaders = {
        Authorization: `Bearer ${clientToken}`,
        "Db-Token": clientDbToken,
      };
    } else {
      throw new Error("No valid authentication tokens found.");
    }
    const response = await axios.post(
      `${API_BASE_URL}/grade-system/list`,
      credentials,
      {
        headers: tokenHeaders,
      },
    );

    if (response?.data?.success) {
      return response?.data?.data;
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({
        message: response?.data?.message || "An error occurred",
        status: response?.status || 400,
      });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    toastError(errorMessage);
    return rejectWithValue({
      message: errorMessage,
      status: error.response?.status || 500,
    });
  }
});

// grades for pie chart Slice here //

export const gradesSlice = createSlice({
  name: "gradesState",
  initialState: initialGradesState,
  reducers: {
    gradesData: (state, action: PayloadAction<any>) => {
      state.gradesList = action.payload;
    },
    resetGradesList: () => initialGradesState,
    clearGradesError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gradesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(gradesAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.gradesList = action.payload;
      })
      .addCase(gradesAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetGradesList, clearGradesError } = gradesSlice.actions;

export const selectGradesDataList = (state: RootState) =>
  state.gradesState?.gradesList ?? [];

// School performance pie chart showing according to session id  //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialSchoolWiseDataState = {
  schoolWiseDataList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface SchoolWiseDataDetails {
  session_id: string;
}

// school wise data Async here //
export const schoolWiseDataAsync = createAsyncThunk<
  any,
  SchoolWiseDataDetails,
  { rejectValue: ErrorPayload }
>(
  "school/data",
  async (credentials: SchoolWiseDataDetails, { rejectWithValue }) => {
    try {

      let tokenHeaders = {};

      // Retrieve tokens from localStorage
      const reportToken = localStorage.getItem("reportToken");
      const reportdb_token = localStorage.getItem("reportdb_token");
      const SToken = localStorage.getItem("S_token");
      const Sdb_token = localStorage.getItem("S_db_token");
      const clientToken = localStorage.getItem("client_token");
      const clientDbToken = localStorage.getItem("client_db_token");

      if (reportToken && reportdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${reportToken}`,
          "Db-Token": reportdb_token,
        };
      } else if (SToken && Sdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${SToken}`,
          "Db-Token": Sdb_token,
        };
      } else if (clientToken && clientDbToken) {
        tokenHeaders = {
          Authorization: `Bearer ${clientToken}`,
          "Db-Token": clientDbToken,
        };
      } else {
        throw new Error("No valid authentication tokens found.");
      }

      const response = await axios.post(
        `${API_BASE_URL}/report/school-performance`,
        credentials,
        {
          headers: tokenHeaders,
        },
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// School Wise Data Slice here //

export const schoolWiseDataSlice = createSlice({
  name: "schoolWiseDataState",
  initialState: initialSchoolWiseDataState,
  reducers: {
    resetSchoolWiseDataList: (state) => {
      state.schoolWiseDataList = [];
    },
    clearSchoolWiseDataError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(schoolWiseDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        schoolWiseDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          if (action.payload && action.payload.subjectWisePerformance) {
            state.schoolWiseDataList = action.payload.subjectWisePerformance;
          } else {
            state.schoolWiseDataList = [];
          }
        }
      )
      .addCase(schoolWiseDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetSchoolWiseDataList, clearSchoolWiseDataError } =
  schoolWiseDataSlice.actions;

export const selectSchoolWiseDataList = (state: RootState) =>
  state.schoolWiseDataState?.schoolWiseDataList ?? [];

// staff performance pie chart showing according to session id  //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialStaffWiseDataState = {
  staffWiseDataList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface StaffWiseDataDetails {
  session_id: string;
}

// staff wise data Async here //

export const staffWiseDataAsync = createAsyncThunk<
  any,
  StaffWiseDataDetails,
  { rejectValue: ErrorPayload }
>(
  "staff/data",
  async (credentials: StaffWiseDataDetails, { rejectWithValue }) => {
    try {

      let tokenHeaders = {};

      // Retrieve tokens from localStorage
      const reportToken = localStorage.getItem("reportToken");
      const reportdb_token = localStorage.getItem("reportdb_token");
      const SToken = localStorage.getItem("S_token");
      const Sdb_token = localStorage.getItem("S_db_token");
      const clientToken = localStorage.getItem("client_token");
      const clientDbToken = localStorage.getItem("client_db_token");

      if (reportToken && reportdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${reportToken}`,
          "Db-Token": reportdb_token,
        };
      } else if (SToken && Sdb_token) {
        tokenHeaders = {
          Authorization: `Bearer ${SToken}`,
          "Db-Token": Sdb_token,
        };
      } else if (clientToken && clientDbToken) {
        tokenHeaders = {
          Authorization: `Bearer ${clientToken}`,
          "Db-Token": clientDbToken,
        };
      } else {
        throw new Error("No valid authentication tokens found.");
      }

      const response = await axios.post(
        `${API_BASE_URL}/report/staff-performance`,
        credentials,
        {
          headers: tokenHeaders,
        },
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        const errorMessage = response?.data?.message;
        toastError(errorMessage);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toastError(errorMessage);

      if (
        error.response?.data?.error?.includes("Cannot read properties of null")
      ) {
        console.error("Data issue:", error.response.data);
      }

      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Staff Wise Data Slice here //

export const staffWiseDataSlice = createSlice({
  name: "staffWiseDataState",
  initialState: initialStaffWiseDataState,
  reducers: {
    resetStaffWiseDataList: (state) => {
      state.staffWiseDataList = [];
    },
    clearStaffWiseDataError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(staffWiseDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        staffWiseDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          if (action.payload && action.payload.staff_wise_performance) {
            state.staffWiseDataList = action.payload.staff_wise_performance;
          } else {
            state.staffWiseDataList = [];
          }
        }
      )
      .addCase(staffWiseDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetStaffWiseDataList, clearStaffWiseDataError } =
  staffWiseDataSlice.actions;

export const selectStaffWiseDataList = (state: RootState) =>
  state.staffWiseDataState?.staffWiseDataList ?? [];