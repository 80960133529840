import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Spinner } from "react-bootstrap";
import "./style.scss";
import * as yup from "yup";
import { deleteIcon, editIcon } from "assets";
import { Question } from "types/schoolAdmin/questionTypes";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  ClassesListAsync,
  createClassAsync,
  deleteClassesAsync,
  DisplayClassesDataList,
  selectClassListCount,
  updateClassesAsync,
} from "redux/features/classesSlice";
import { RootState, store, useTypedSelector } from "redux/store";
import { toastError } from "helpers/toastHelper";
import ReactPaginate from "react-paginate";
import DeleteConfirmationModal from "components/delete-modal";

// Types Define here //
const initialValues: Question.ClassesType = {
  title: "",
  description: "",
  id: undefined,
};

// Validation here //
const validationSchema = yup.object().shape({
  title: yup.string().required("Please enter name"),
});

// Class components here //

const ClassesComponent = () => {
  // Manage states here //
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const isLoading = useTypedSelector(
    (state: RootState) => state.classesState.isLoading
  );

  let dispatch = useDispatch<typeof store.dispatch>();

  const classesDataList = useTypedSelector(DisplayClassesDataList);
  const totalClassCount = useTypedSelector(selectClassListCount);

  const handleModalOpen = (id: string) => {
    setIsEditing(false);
    setShowModal(true);
    setDeletingId(id);
  };

  // Edit classes logic here //
  const handleEdit = (id: string, data) => {
    const formData = {
      title: data.title || "",
      description: data.description || "",
      id: data.id,
    };

    formik.setValues(formData);
    setIsEditing(true);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };
  const handleShow = (data: Question.ClassesType) => {
    formik.setValues(data);
    setShow(true);
  };

  const onSubmit = () => {
    formik.submitForm();
  };

  const handleFieldChange = (field: string, value: any) => {
    handleChange({ target: { name: field, value: value ? value.name : "" } });
  };

  // Formik here //
  const formik = useFormik({
    initialValues,

    validationSchema,

    onSubmit: async (values, { resetForm }) => {
      let response: any = {};
      if (isEditing) {
        const updateData = {
          id: values.id,
          classesDetails: {
            title: values.title,
            description: values.description,
          },
        };

        response = await dispatch(updateClassesAsync(updateData));
      } else {
        response = await dispatch(createClassAsync(values));
      }
      if (response?.payload?.success === true) {
        handleClose();
        resetForm();
      }
      fetchData(currentPage);
    },
  });

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = formik;

  // Add Class logic here //
  const handleAddNew = () => {
    formik.resetForm();
    setIsEditing(false);
    setShow(true);
  };

  // Filtered classes list here //
  const filteredClassesDataList = classesDataList.filter((classItem) =>
    classItem.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // search logic here //
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Fetch classes logic here //
  const fetchData = (page: number) => {
    setLoading(true);
    const payload = {
      searchQuery,
      page: page + 1,
      pageSize: itemsPerPage,
    };
    dispatch(ClassesListAsync(payload)).then((response) => {
      if (response.payload) {
        setTotalCount(response.payload.count);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [searchQuery, currentPage, dispatch]);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
    fetchData(data.selected);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeletingId(null);
  };

  // Delete Classes logic here //
  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      setIsDeleting(true);
      try {
        await dispatch(deleteClassesAsync(deletingId));
        fetchData(currentPage);
        setShowModal(false);
        setDeletingId(null);
      } catch (error) {
        toastError("Error deleting session");
      } finally {
        setIsDeleting(false);
      }
    } else {
      toastError("Cannot delete: No ID provided");
    }
  };
  // tsx here //
  return (
    <>
      <div className="container">
        <div className="inner-section d-flex gap-1 flex-wrap items-center justify-content-between">
          <div className="left-area">
            <h6>Class List</h6>
          </div>
          <div className="right-area">
            <button
              className="custom-active-button rounded-lg"
              onClick={handleAddNew}
            >
              Add New Class
            </button>
          </div>
        </div>
      </div>

      {/* Action area(search and filter fields) */}
      <div className="container">
        <div className="action-area">
          <div className="search-div">
            <span className="search-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#a5a5a5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-search"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="m21 21-4.3-4.3" />
              </svg>
            </span>
            <input
              type="text"
              placeholder="Search by Class Name"
              value={searchQuery}
              onChange={handleSearch}
              className="form-control"
            />
          </div>
          <div className="search-result">
            {totalCount > 0 ? `${totalCount} records found` : "0 records found"}
          </div>
        </div>

        {/* Table data here */}
        <div className="responsive-table">
          {loading ? (
            <div className="loader d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Class</th>
                    <th style={{ width: "40%" }}>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClassesDataList?.length > 0 ? (
                    filteredClassesDataList?.map((data, itemIndex) => {
                      const serialNumber =
                        currentPage * itemsPerPage + itemIndex + 1;
                      return (
                        <tr key={`table-body-${itemIndex}`}>
                          <td>{serialNumber}</td>
                          <td>{data?.title || "N/A"}</td>
                          <td>
                            <div className="description">
                              {data?.description || "N/A"}
                            </div>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              <span>
                                <button
                                  title="Edit"
                                  onClick={() => handleEdit(data?.id, data)}
                                  style={{ border: "none" }}
                                >
                                  <img
                                    src={editIcon}
                                    alt="edit-icon"
                                    className="cursor-pointer"
                                  />
                                </button>
                              </span>
                              <span>
                                <button
                                  title="Delete"
                                  style={{ border: "none" }}
                                  onClick={() => handleModalOpen(data?.id)}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete-icon"
                                    className="cursor-pointer"
                                  />
                                </button>
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* pagination here */}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(totalClassCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            </>
          )}
        </div>
      </div>

      {/* Delete class modal here  */}

      <DeleteConfirmationModal
        show={showModal}
        onHide={handleModalClose}
        onConfirmDelete={handleConfirmDelete}
        isDeleting={isDeleting}
      />

      {/* Add and Edit class Modal here  */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            {" "}
            {isEditing ? "Update Class" : "Create New Class"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            action=""
            className="horizontal-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-group mb-4">
              <label htmlFor="">
                Name<span className="form_required">*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Enter name here"
                value={values?.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.title && touched.title && (
                <div className="error">{errors.title}</div>
              )}
            </div>

            <div className="form-group mb-4">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                className="form-control"
                placeholder=""
                id="description"
                cols={30}
                rows={5}
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="form-group mb-4">
              <button
                className="w-full custom-active-button rounded-lg"
                type="submit"
                disabled={isLoading || formik.isSubmitting}
              >
                {isLoading || formik.isSubmitting
                  ? isEditing
                    ? "Updating..."
                    : "Saving..."
                  : isEditing
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClassesComponent;

// class components done //
