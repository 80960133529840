import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL, SUB_DOMAIN } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Function to get subdomain from localStorage //
const getSubdomain = () => {
  return localStorage.getItem("subdomain");
};

interface UserDetailState {
  LoggedUserDetailsList: Array<any>;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserDetailState = {
  LoggedUserDetailsList: [],
  isLoading: false,
  error: null,
};

interface FetchParams {
  token: string;
}

// Logged in user details Async here //
export const loggedInUserDetailsAsync = createAsyncThunk(
  "userDetails/fetchDetails",
  async (_, { rejectWithValue }) => {
    const subdomain = getSubdomain();

    // Prioritize S_token and S_db_token
    let token = localStorage.getItem("S_token") || localStorage.getItem(subdomain ? "client_token" : "token");
    let db_token = localStorage.getItem("S_db_token") || localStorage.getItem(subdomain ? "client_db_token" : "db_token");

    if (!token || !db_token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/client/get-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Db-Token": db_token,
        },
      });

      if (response?.data?.status === 200) {
        localStorage.setItem("school_name", response?.data?.data?.school_name);
        localStorage.setItem("user", response?.data?.data?.email);
        localStorage.setItem("role_id", response?.data?.data?.role_id);
        localStorage.setItem("client_id", response?.data?.data?.client_id)
        localStorage.setItem("client_email", response?.data?.data?.email)
        localStorage.setItem("student_id", response?.data?.data?.id)
        localStorage.setItem("grade_id", response?.data?.data?.grade_id)
        localStorage.setItem("session_id", response?.data?.data?.session_id)
        return response?.data?.data;
      } else {
        toastError(response.data.message);
        return rejectWithValue(response.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Unknown error occurred";
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status || 500,
      });
    }
  }
);


// Logged in user details Slice here //

export const loggedUserDetailsSlice = createSlice({
  name: "loggedUserDetailsState",
  initialState,
  reducers: {
    loggedInUserData: (state, action) => {
      state.LoggedUserDetailsList = action.payload;
    },
    resetList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggedInUserDetailsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loggedInUserDetailsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.LoggedUserDetailsList = [
          ...state.LoggedUserDetailsList,
          action.payload,
        ];
        localStorage.setItem("school_name", action.payload?.school_name);
      })
      .addCase(loggedInUserDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});


// selectors //
export const { resetList, loggedInUserData } = loggedUserDetailsSlice.actions;

export default loggedUserDetailsSlice.reducer;

export const selectLoggedUserDetailsList = (state: RootState) =>
  state.loggedUserDetailsState?.LoggedUserDetailsList ?? [];
