

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// initial state here //
const initialState = {
  chapterList: [],
  isLoading: false,
  error: null,
};

interface ChapterDetails {
  chapter: string;
  grade_id: number[];
  subject_id: number[];
}

// chapter create Async here //
export const createChapterAsync = createAsyncThunk(
  "chapter/create",
  async (credentials: ChapterDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/chapter/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// chapter create Slice here //

const chapterSlice = createSlice({
  name: "chapterState",
  initialState,
  reducers: {
    chapterData: (state: any, action) => {
      state.chapterList.push(action.payload);
    },
    resetChapterList: () => initialState,
    clearChapterError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChapterAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createChapterAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.chapterList = [...state.chapterList, action.payload];
      })
      .addCase(createChapterAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const { resetChapterList, clearChapterError } = chapterSlice.actions;
export default chapterSlice.reducer;

export const selectChapterDataList = (state: RootState) =>
  state.chapterState?.chapterList ?? [];

// chapter list ///

interface ErrorPayload {
  message: string;
  status: number;
}

// chapter list details //
interface ChapterListDetails {
  id: number;
  chapter: string;
  description: string;
  status: boolean;
  grades: string[];
  subjects: string[];
}

interface ChapterListState {
  chapterDataList: ChapterListDetails[];
  isLoading: boolean;
  error: string | null;
  totalPages: number;
  count: number;
}

const chapterListInitialState: ChapterListState = {
  chapterDataList: [],
  isLoading: false,
  error: null,
  totalPages: 0,
  count: 0,
};

// chapter list Async here //
export const ChapterListAsync = createAsyncThunk<
  { chapters: ChapterListDetails[]; count: number },
  {
    gradeId?: number[];
    subjectId?: number[];
    chapterId?: number[];
    statusId?: boolean;
    searchQuery?: string;
    page?: number;
    pageSize?: number;
    isAllDataFetch?: boolean;
  },
  { rejectValue: ErrorPayload }
>(
  "chapter/create/list",
  async (
    {
      gradeId,
      subjectId,
      chapterId,
      statusId,
      searchQuery,
      page,
      pageSize,
      isAllDataFetch,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (subjectId) filter.subject_id = subjectId;
      if (chapterId) filter.id = chapterId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.chapter = searchQuery;

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/chapter/list`,
        {
          filter,
          range: Object.keys(range).length ? range : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken || reportToken}`,
            "db-token": db_token || Sdb_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const chapters = response?.data?.data;
        const count = response?.data?.count;
        return { chapters, count };
      } else {
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// chapter list Slice here //

export const chapterListSlice = createSlice({
  name: "chapterListState",
  initialState: chapterListInitialState,
  reducers: {
    clearChapterList: () => chapterListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChapterListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        ChapterListAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.chapterDataList = action.payload.chapters;
          state.count = action.payload.count;
        }
      )
      .addCase(ChapterListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearChapterList } = chapterListSlice.actions;
export const chapterListReducer = chapterListSlice.reducer;

export const DisplayChapterDataList = (state: RootState) =>
  state.chapterListState
    ? {
        chaptersDataList: state.chapterListState.chapterDataList,
        isLoading: state.chapterListState.isLoading,
      }
    : [];
export const selectChapterListCount = (state: RootState) =>
  state.chapterListState.count;



// // update chapter values//

interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateChapterDetails {
  [x: string]: any;
  chapter: string;
  grade_id: number[];
  subject_id: number[];
  description: string;
}

interface UpdateChapterState {
  updateChapterList: UpdateChapterDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateChapterState = {
  updateChapterList: [],
  isLoading: false,
  error: null,
};

// update chapter Async here //
export const updateChapterAsync = createAsyncThunk(
  "chapter/update",
  async (
    { id, chapterDetails }: { id: any; chapterDetails: UpdateChapterDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chapter/update/${id}`,
        chapterDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update chapter slice //
export const updateChapterSlice = createSlice({
  name: "updateChapterState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedChapter: (state) => {
      state.updateChapterList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateChapterAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateChapterAsync.fulfilled,
        (state, action: PayloadAction<UpdateChapterDetails>) => {
          state.isLoading = false;
          const index = state.updateChapterList.findIndex(
            (chapter) => chapter.id === action.payload.id
          );
          if (index !== -1) {
            state.updateChapterList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateChapterAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedChapter } = updateChapterSlice.actions;
export const updateChapterReducer = updateChapterSlice.reducer;
export const selectUpdateChapterDataList = (state: RootState) => {
  return state.updateChapterState?.updateCHapterList ?? [];
};

//Delete chapter Value //

interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteChapterState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteChapterState = {
  isLoading: false,
  error: null,
};

// Delete chapter Async here //
export const deleteChapterAsync = createAsyncThunk(
  "chapter/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chapter/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete chapter Slice here //

export const deleteChapterSlice = createSlice({
  name: "deleteChapterState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteChapter: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteChapterAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteChapterAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteChapterAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearDeleteChapter } = deleteChapterSlice.actions;
export const deleteChapterReducer = deleteChapterSlice.reducer;

// handle chapter status //

interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateChapterStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateChapterStatusState {
  updateChapterStatusList: UpdateChapterStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateChapterStatusState = {
  updateChapterStatusList: [],
  isLoading: false,
  error: null,
};

// update Chapter Status Async here //
export const updateChapterStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateChapterStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("chapter/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");
  const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/chapter/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response.data.data.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// update Chapter Status Slice here //

export const updateChapterStatusSlice = createSlice({
  name: "updateChapterStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedChapterStatus: (state) => {
      state.updateChapterStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateChapterStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateChapterStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateChapterStatusList.findIndex(
            (chapter) => chapter.id === action.payload.id
          );
          if (index !== -1) {
            state.updateChapterStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateChapterStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateChapterStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedChapterStatus } = updateChapterStatusSlice.actions;
export const updateChapterStatusReducer = updateChapterStatusSlice.reducer;
export const selectUpdateChapterStatusDataList = (state: RootState) => {
  return state.updateChapterStatusState?.updateChapterStatusList ?? [];
};

// upload chapter  //
interface UploadChapterDetails {
  file: File;
  additionalData: any;
}

// Upload Chapter Async here //
export const uploadChapterAsync = createAsyncThunk(
  "chapter/upload",
  async (
    { file, additionalData }: UploadChapterDetails,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const formData = new FormData();
    formData.append("file", file);
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chapter/chapterUpload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

interface UploadChapterState {
  isLoading: boolean;
  error: string | null;
}

const initialUploadState: UploadChapterState = {
  isLoading: false,
  error: null,
};

// Upload Chapter Slice here //

export const uploadChapterSlice = createSlice({
  name: "uploadChapterState",
  initialState: initialUploadState,
  reducers: {
    clearUploadError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadChapterAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadChapterAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadChapterAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { clearUploadError } = uploadChapterSlice.actions;

export const selectUploadChapterError = (state: RootState) =>
  state.uploadChapterState.error;
export const selectUploadChapterLoading = (state: RootState) =>
  state.uploadChapterState.isLoading;
