import dashboardIcon from "./svg/dashboard.svg";
import closeIcon from "./svg/close.svg";
import logoutIcon from "./svg/logout.svg";
import levelIcon from "./svg/level.svg";
import topicIcon from "./svg/topic.svg";
import skillIcon from "./svg/skill.svg";
import questionIcon from "./svg/question.svg";
import studentIcon from "./svg/student.svg";
import superAdminIcon from "./svg/super-admin.svg";
import schoolAdminIcon from "./svg/school-admin.svg";
import sessionIcon from "./svg/session-icon.svg";
import schoolIcon from "./svg/school-icon.svg";
import rolesIcon from "./svg/roles-icon.svg";
import classIcon from "./svg/class-icon.svg";
import sectionIcon from "./svg/section-icon.svg";
import subjectIcon from "./svg/subject-icon.svg";
import chapterIcon from "./svg/chapter-icon.svg";
import circleQuestionIcon from "./svg/circle-question.svg";
import reportIcon from "./svg/report-icon.svg";
import accessIcon from "./svg/access-icon.svg";
import teacherIcon from "./svg/teacher-icon.svg";
import newUserIcon from "./svg/user-icon.svg";
import sendIcon from "./svg/send-icon.svg";
import loginIcon from "./svg/login-icon.svg";
import newFilterIcon from "./svg/newFilterIcon.svg";
import crossIcons from "./svg/crossIcon.svg";
import collapseIcon from "./svg/collapse-icon.svg";
import schoolReportIcon from "./svg/school-report.svg"
import teacherReportIcon from "./svg/teacher-reports.svg"
import studentReportIcon from "./svg/student-report.svg"

// sidebar icons

import burgermenuIcon from "./svg/burgermenu.svg";
import searchIcon from "./svg/search.svg";
import bellIcon from "./svg/bell.svg";
import userIcon from "./svg/user.svg";
import passwordIcon from "./svg/password.svg";
import logoffIcon from "./svg/logoff.svg";
import downarrowIcon from "./svg/downarrow.svg";
import uparrowIcon from "./svg/uparrow.svg";
import downloadIcon from "./svg/download.svg";
import printIcon from "./svg/print.svg";
import gridIcon from "./svg/grid.svg";
import filterIcon from "./svg/filter.svg";
import editIcon from "./svg/edit.svg";
import viewIcon from "./svg/view.svg";
import deleteIcon from "./svg/delete.svg";
import plusIcon from "./svg/plus-icon.svg";
import addIcon from "./svg/add.svg";
import questionPaperIcon from "./svg/question-paper.svg";
import examIcon from "./svg/exam.svg";
import profileIcon from "./svg/profile-icon.svg";
import instructionIcon from "./svg/instruction.svg";

// All SVG exports
export {
  // sidebar icons
  dashboardIcon,
  closeIcon,
  logoutIcon,
  levelIcon,
  topicIcon,
  skillIcon,
  questionIcon,
  studentIcon,
  superAdminIcon,
  schoolAdminIcon,
  sessionIcon,
  schoolIcon,
  rolesIcon,
  classIcon,
  sectionIcon,
  subjectIcon,
  chapterIcon,
  circleQuestionIcon,
  reportIcon,
  accessIcon,
  teacherIcon,
  newUserIcon,
  sendIcon,
  loginIcon,
  newFilterIcon,
  crossIcons,
  questionPaperIcon,
  examIcon,
  profileIcon,
  instructionIcon,
  collapseIcon,
  schoolReportIcon,
  teacherReportIcon,
  studentReportIcon,

  // sidebar icons
  burgermenuIcon,
  searchIcon,
  bellIcon,
  userIcon,
  passwordIcon,
  logoffIcon,
  downarrowIcon,
  uparrowIcon,
  downloadIcon,
  printIcon,
  gridIcon,
  filterIcon,
  editIcon,
  viewIcon,
  deleteIcon,
  plusIcon,
};
