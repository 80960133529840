import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  createQuestionList: [],
  isLoading: false,
  error: null,
};

// Create Question Details here //
interface createQuestionDetails {
  answer_text: string;
  question_text: string;
  grade_id: number[];
  subject_id: number[];
  type: string;
  level: string;
  max_marks: number | null;
}

// Create Question Async here //
export const CreateQuestionAsync = createAsyncThunk(
  "question/create",
  async (credentials: createQuestionDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/question/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Question Slice here //

const createQuestionSlice = createSlice({
  name: "createQuestionState",
  initialState,
  reducers: {
    createQuestionData: (state: any, action) => {
      state.questionList.push(action.payload);
    },
    resetCreateQuestionList: () => initialState,
    clearCreateQuestionError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateQuestionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(CreateQuestionAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.createQuestionList = [
          ...state.createQuestionList,
          action.payload,
        ];
      })
      .addCase(CreateQuestionAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetCreateQuestionList, clearCreateQuestionError } =
  createQuestionSlice.actions;
export default createQuestionSlice.reducer;

export const selectCreateQuestionDataList = (state: RootState) =>
  state.createQuestionState?.createQuestionList ?? [];

// question list here //


interface ErrorPayload {
  message: string;
  status: number;
}

// question list Details here //
interface QuestionListDetails {
  id: number;
  title: string;
  sub_topic: string;
  description: string;
  status: boolean;
  grades: number[];
  subjects: number[];
  chapter: number[];
  question_options?: { data: string; option: number }[];
  isAllDataFetch?: boolean
}

interface QuestionListState {
  questionDataList: QuestionListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

// Initial state
const questionListInitialState: QuestionListState = {
  questionDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

// Create Question List Async here //

export const QuestionListAsync = createAsyncThunk(
  "question/create/list",
  async (
    {
      gradeId,
      subjectId,
      topicId,
      typeId,
      levelId,
      skillId,
      marksId,
      statusId,
      searchQuery,
      chapterId,
      page,
      pageSize,
      isAllDataFetch,
      sort,
    }: {
      gradeId?: number[];
      subjectId?: number[];
      chapterId?: number[];
      topicId?: number[];
      typeId?: string[];
      levelId?: string[];
      skillId?: string[];
      marksId?: string[];
      statusId?: boolean;
      searchQuery?: string;
      page?: number;
      pageSize?: number;
      isAllDataFetch,
      sort?: { field: string; order: string };
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (subjectId) filter.subject_id = subjectId;
      if (topicId) filter.topic_id = topicId;
      if (typeId) filter.type = typeId;
      if (levelId) filter.level = levelId;
      if (skillId) filter.skill = skillId;
      if (marksId) filter.max_marks = marksId;
      if (chapterId) filter.chapter_id = chapterId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.search = searchQuery;

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/question/list`,
        { filter, range: Object.keys(range).length ? range : undefined, sort: sort ? sort : undefined, },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const questions = response?.data?.data as QuestionListDetails[];
        return { questions, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Question List Slice here //

export const questionListSlice = createSlice({
  name: "questionListState",
  initialState: questionListInitialState,
  reducers: {
    DisplayQuestion: (state, action: PayloadAction<QuestionListDetails>) => {
      state.questionDataList.push(action.payload);
    },
    clearQuestionList: () => questionListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuestionListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(QuestionListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionDataList = action.payload
          .questions as QuestionListDetails[];
        state.count = action.payload.count;
      })
      .addCase(QuestionListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "";
        }
      });
  },
});

// Selectors here //
export const { clearQuestionList, DisplayQuestion } = questionListSlice.actions;
export const questionListReducer = questionListSlice.reducer;

export const DisplayQuestionDataList = (state: RootState) =>
  state.questionListState?.questionDataList ?? [];
export const selectQuestionListCount = (state: RootState) =>
  state.questionListState.count;


// // update question values//
interface ErrorPayload {
  message: string;
  status: number;
}
// update Question Details here //
interface UpdateQuestionDetails {
  [x: string]: any;
  answer_text?: string;
  question_text?: string;
  grade_id?: number[];
  subject_id?: number[];
  type?: string;
  level?: string;
  connected_with?: string;
  based_on?: string;
  concept_level?: string;
  skill?: string;
  max_marks?: number | null;
  chapter_id?: number[];
  topic_id?: number[];
  occuring_history?: string;
  board_exam?: string;
  board_exam_year?: string;
  competative_exam?: string;
  competative_exam_year?: string;
}

interface UpdateQuestionState {
  updateQuestionList: UpdateQuestionDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateQuestionState = {
  updateQuestionList: [],
  isLoading: false,
  error: null,
};

// update Question Async here //
export const updateQuestionAsync = createAsyncThunk(
  "question/update",
  async (
    {
      id,
      questionDetails,
    }: { id: any; questionDetails: UpdateQuestionDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question/update/${id}`,
        questionDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.updatedClassesData;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update question slice here//
export const updateQuestionSlice = createSlice({
  name: "updateQuestionState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedQuestion: (state) => {
      state.updateQuestionList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateQuestionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateQuestionAsync.fulfilled,
        (state, action: PayloadAction<UpdateQuestionDetails>) => {
          state.isLoading = false;
          const index = state.updateQuestionList.findIndex(
            (question) => question.id === action.payload.id
          );
          if (index !== -1) {
            state.updateQuestionList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateQuestionAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedQuestion } = updateQuestionSlice.actions;
export const updateQuestionReducer = updateQuestionSlice.reducer;
export const selectUpdateQuestionDataList = (state: RootState) => {
  return state.updateQuestionState?.updateQuestionList ?? [];
};

//Delete question Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteQuestionState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteQuestionState = {
  isLoading: false,
  error: null,
};

// // Delete Question Async here //

export const deleteQuestionAsync = createAsyncThunk(
  "question/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// // Delete Question Slice here //

export const deleteQuestionSlice = createSlice({
  name: "deleteQuestionState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteQuestion: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteQuestionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteQuestionAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteQuestionAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteQuestion } = deleteQuestionSlice.actions;
export const deleteQuestionReducer = deleteQuestionSlice.reducer;

// handle question status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateQuestionStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateQuestionStatusState {
  updateQuestionStatusList: UpdateQuestionStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateQuestionStatusState = {
  updateQuestionStatusList: [],
  isLoading: false,
  error: null,
};

// Update Question Status Async here //
export const updateQuestionStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateQuestionStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("question/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/question/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// Update Question Status Slice here //

export const updateQuestionStatusSlice = createSlice({
  name: "updateQuestionStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedQuestionStatus: (state) => {
      state.updateQuestionStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateQuestionStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateQuestionStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateQuestionStatusList.findIndex(
            (question) => question.id === action.payload.id
          );
          if (index !== -1) {
            state.updateQuestionStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateQuestionStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateQuestionStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedQuestionStatus } = updateQuestionStatusSlice.actions;
export const updateStatusReducer = updateQuestionStatusSlice.reducer;
export const selectUpdateQuestionStatusDataList = (state: RootState) => {
  return state.updateQuestionStatusState?.updateQuestionStatusList ?? [];
};

// Upload Question here //

interface UploadQuestionDetails {
  file: File;
  additionalData: any;
}

// Upload Question Async here //
export const uploadQuestionAsync = createAsyncThunk(
  "question/upload",
  async (
    { file, additionalData }: UploadQuestionDetails,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const formData = new FormData();
    formData.append("file", file);
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question/upload-question`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

interface UploadQuestionState {
  isLoading: boolean;
  error: string | null;
}

const initialUploadState: UploadQuestionState = {
  isLoading: false,
  error: null,
};

// Upload Question Slice here //

export const uploadQuestionSlice = createSlice({
  name: "uploadQuestionState",
  initialState: initialUploadState,
  reducers: {
    clearUploadError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadQuestionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadQuestionAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadQuestionAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { clearUploadError } = uploadQuestionSlice.actions;

export const selectUploadQuestionError = (state: RootState) =>
  state.uploadQuestionState.error;
export const selectUploadQuestionLoading = (state: RootState) =>
  state.uploadQuestionState.isLoading;






// Check Duplicate Question Async here //
interface SimilarQuestionDetails {
  question_text: string;
  grade_id?: number;
  subject_id?: number;
  chapter_id?: number;
  topic_id?: number;
  type?: string;
  level?: string;
  max_marks?: number | null;
}

interface SimilarQuestionState {
  similarQuestionList: SimilarQuestionDetails[];
  loaded: boolean;
  error: string | null;
}

// Initial state
const initialSimilarQuestionState: SimilarQuestionState = {
  similarQuestionList: [],
  loaded: false,
  error: null,
};

// Similar Question Async
export const similarQuestionAsync = createAsyncThunk(
  "question/similar",
  async (credentials: SimilarQuestionDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question/similar-questions`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status || 500,
      });
    }
  }
);

// Similar Question Slice

export const similarQuestionSlice = createSlice({
  name: "similarQuestionState",
  initialState: initialSimilarQuestionState,
  reducers: {
    resetSimilarQuestionList: () => initialSimilarQuestionState,
    clearSimilarQuestionError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(similarQuestionAsync.pending, (state) => {
        state.loaded = false;
        state.error = null;
      })
      .addCase(
        similarQuestionAsync.fulfilled,
        (state, action: PayloadAction<SimilarQuestionDetails[]>) => {
          state.loaded = true;
          state.similarQuestionList = action.payload;
        }
      )
      .addCase(similarQuestionAsync.rejected, (state, action) => {
        state.loaded = true;
      });
  },
});


// Selectors and Exports
export const { resetSimilarQuestionList, clearSimilarQuestionError } =
  similarQuestionSlice.actions;

export const selectSimilarQuestionDataList = (state: RootState) =>
  state.similarQuestionState?.similarQuestionList ?? [];

