import { useEffect } from "react";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { GlobalErrorBoundary, ScrollTop } from "components";
import { useAppDispatch } from "redux/store";
import {
  getUserFromStore,
  updateWindowWidth,
} from "redux/features/appStateSlice";
import { getUser } from "helpers/storage";
import { useNavigate } from "react-router-dom";

function App() {
  const user = getUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((user ?? "").trim() === "") {
      dispatch(getUserFromStore({}));
    }
    const handleWindowResize = () => {
      dispatch(updateWindowWidth({ width: window.innerWidth }));
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(1);
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => <GlobalErrorBoundary {...errorData} />}
    >
      <ScrollTop>
        <Routes />
        <ToastContainer />
      </ScrollTop>
    </Sentry.ErrorBoundary>
  );
}

export default App;
