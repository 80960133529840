import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Menu, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  burgermenuIcon,
  logoffIcon,
  passwordIcon,
  userIcon,
} from "../../assets";
import "./styles.scss";
import { store, useTypedSelector } from "redux/store";
import {
  selectIsShowSidebar,
  updateIsShowSidebar,
} from "redux/features/appStateSlice";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Question } from "types/schoolAdmin/questionTypes";
import { useFormik } from "formik";
import {
  resetPasswordAsync,
  selectResetPasswordState,
} from "redux/features/resetPasswordSlice";
import {
  SessionListAsync,
  DisplaySessionDataList,
} from "redux/features/sessionSlice";
import {
  setSelectedSessionId,
  selectSelectedSessionId,
} from "redux/features/sessionSlice1";
import { useNavigate } from "react-router-dom";
import { loggedInUserDetailsAsync } from "redux/features/getDetailsLoggedInUserSlice";
import { schoolAdminLoginAsync } from "redux/features/schoolAdminSlice";
import { fetchActiveModulesAsync } from "redux/features/moduleWiseAccessSlice";
import { superAdminRoutes } from "routes/AppRoutes";

// Initial Type Define and Define Types //
const initialValues: Question.ResetPasswordType = {
  oldPassword: "",
  password: "",
  confirm_password: "",
};

const values = {
  email: "",
  client_id: "",
}

// validation message here //
const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("please enter old password"),
  password: yup.string().required("Please enter new password"),
  confirm_password: yup.string().required("Please confirm your new password"),
});

// get sub-domain here from local storage //
const getSubdomain = () => {
  return localStorage.getItem("subdomain");
};

// Topbar component here //
const Topbar = (props: any) => {
  // Manage states here //
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleShow = () => setShow(true);
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const dispatch = useDispatch<typeof store.dispatch>();
  const sessionSelected = useTypedSelector(selectSelectedSessionId);
  const [itemsPerPage] = useState(1000);
  const [page] = useState(1);

  const role_id = localStorage.getItem("role_id");
  const subdomain = getSubdomain();

  // update Side bar based on sub-domain and tokens //
  const setSidebar = (show = false) => {
    dispatch(updateIsShowSidebar({ show }));
  };

  const resetPasswordState = useSelector(selectResetPasswordState);

  useEffect(() => {
    dispatch(SessionListAsync({ page, pageSize: itemsPerPage }));
  }, [dispatch, page, itemsPerPage]);

  const sessions = useSelector(DisplaySessionDataList);

  useEffect(() => {
    const storedSessionId = localStorage.getItem("selectedSessionId");

    if (sessions?.length > 0) {
      if (storedSessionId) {
        dispatch(setSelectedSessionId(Number(storedSessionId)));
      } else {
        const defaultSession = sessions.find((session) => session?.is_default);
        if (defaultSession) {
          dispatch(setSelectedSessionId(defaultSession?.id));
        } else {
          dispatch(setSelectedSessionId(sessions[0]?.id));
        }
      }
    }
  }, [sessions, dispatch]);

  // session change logic here //
  const handleSessionChange = (selectedSession) => {
    dispatch(setSelectedSessionId(selectedSession?.id));
    localStorage.setItem("selectedSessionId", selectedSession?.id);
  };

  // Formik and Submit logic here //
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        password: values?.oldPassword,
        new_password: values?.password,
      };
      dispatch(resetPasswordAsync(payload)).then((result: any) => {
        handleClose();
        if (resetPasswordState?.success) {
          resetForm({
            values: {
              oldPassword: "",
              password: "",
              confirm_password: "",
            },
          });
        } else if (resetPasswordState.error) {
        }
      });
    },
  });

  // Formik values here //
  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    formik;

  // log out logic here //
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  // Getting Token from local storage here //
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");

  let userRoles;

  if ((SToken && Sdb_token) || subdomain) {
    userRoles = "School Admin";
  } else {
    userRoles = "Super Admin";
  }

  const navigate = useNavigate();


  // handle super admin logic here //

  const findFirstAccessibleRoute = (activeModules, routes) => {
    const role_id = localStorage.getItem("role_id");
    const flatModules = activeModules.find(ele => ele.role_id == role_id);

    if (!flatModules) return null;

    for (const module of flatModules.modules) {
      if (module.module_permissions.some(perm => perm.action === "view" && perm.status)) {
        const matchingRoute = routes.find(route => route.state.toLowerCase() === module.module.toLowerCase());
        if (matchingRoute) {
          return matchingRoute.path;
        }
      }
    }
    return null;
  };


  const handleSuperAdmin = async () => {
    try {
      setIsLoading(true);

      const email = localStorage.getItem("client_email");
      const client_id = localStorage.getItem("client_id");

      if (!email || !client_id) {
        navigate("/super-admin/sessions");
        window.location.reload();
        return;
      }

      await dispatch(schoolAdminLoginAsync({ client_id, email })).unwrap();

      localStorage.removeItem("S_token");
      localStorage.removeItem("S_db_token");

      await dispatch(loggedInUserDetailsAsync());

      const activeModules = await dispatch(fetchActiveModulesAsync()).unwrap();

      const firstAccessibleRoute = findFirstAccessibleRoute(activeModules, superAdminRoutes);

      // Now update sidebar visibility//
      dispatch(updateIsShowSidebar({ show: true }));

      // Navigate to first accessible route if found
      navigate(firstAccessibleRoute || "/super-admin/sessions");
      window.location.reload();

    } catch (error) {
      console.error("Error in handleSuperAdmin:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <div className={`container-fluid ${!props.showComps && "hidden"}`}>
        <div className="topbar">
          <div className="left-side">
            <span
              className="burger-menu sidebar-toggle"
              onClick={() => setSidebar(!isShowSidebar)}
            >
              <img src={burgermenuIcon} alt="menu" title="Side-menu" />
            </span>
            <p className="subject-name rounded-full">{userRoles}</p>
          </div>

          {/* Session Dropdown here while login as a super admin  */}
          <div className="right-side">
            {/* {((SToken && Sdb_token) || subdomain) && ( */}
            {role_id !== "4" && ((SToken && Sdb_token) || subdomain) && (
              <form action="" className="min-w-60">
                <div className="form-group">
                  <Listbox
                    value={sessionSelected}
                    onChange={handleSessionChange}
                  >
                    <div className="relative">
                      <Listbox.Button className="relative text-left">
                        <span className="block truncate">
                          {sessionSelected
                            ? sessions.find(
                              (item) => item?.id === sessionSelected
                            )?.name
                            : "Select Session"}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-0 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {sessions?.map((session, sessionIdx) => (
                            <Listbox.Option
                              key={sessionIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-8 pr-4 ${active
                                  ? "bg-orange-100 text-orange-700"
                                  : "text-gray-900"
                                }`
                              }
                              value={session}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                      }`}
                                  >
                                    {session?.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </form>
            )}

            {/* show back to super admin button when login as a super admin  */}
            {SToken && Sdb_token && (
              <button
                className="custom-active-button rounded-lg flex items-center gap-2"
                onClick={handleSuperAdmin}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-md z-50">
                      <div className="flex flex-col items-center gap-2 text-white">
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 108 8h-4l3 3 3-3h-4a8 8 0 01-8-8z"
                          ></path>
                        </svg>
                        Please wait...
                      </div>
                    </div>
                  </>
                ) : (
                  "Back to Super Admin"
                )}
              </button>
            )}

            {/* Log out and reset password button show  */}
            <span className="user-menu">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                    <img
                      src={userIcon}
                      alt="user"
                      title="Log-out or Reset Password"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-[9999] mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleShow}
                            className={`${active
                              ? "bg-orange-100 text-orange-700"
                              : "text-gray-700"
                              } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            <img
                              src={passwordIcon}
                              alt=""
                              title="Reset-password"
                            />
                            Reset Password
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleLogout}
                            className={`${active
                              ? "bg-orange-100 text-orange-700"
                              : "text-gray-700"
                              } group flex w-full gap-1 items-center rounded-md px-2 py-
                            px-2 py-2 text-sm`}
                          >
                            <img src={logoffIcon} alt="" title="Log-out" />
                            Log Out
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span>
          </div>
        </div>
      </div >

      {/* Reset Password Modal here  */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Reset Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="" className="horizontal-form" onSubmit={handleSubmit}>
            <div className="form-group mb-4">
              <label htmlFor="">
                Old Password<span className="form_required">*</span>
              </label>
              <input
                id="oldPassword"
                type="password"
                className="form-control"
                placeholder="Type Old Password"
                value={values?.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.oldPassword && touched.oldPassword && (
                <div className="error">{errors.oldPassword}</div>
              )}
            </div>
            <div className="form-group mb-4">
              <label htmlFor="">
                Password<span className="form_required">*</span>
              </label>
              <input
                id="password"
                type="password"
                className="form-control"
                placeholder="Type New Password"
                value={values?.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password && (
                <div className="error">{errors.password}</div>
              )}
            </div>
            <div className="form-group mb-4">
              <label htmlFor="">
                Confirm Password<span className="form_required">*</span>
              </label>
              <input
                id="confirm_password"
                type="password"
                className="form-control"
                placeholder="Confirm New Password"
                value={values?.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirm_password && touched.confirm_password && (
                <div className="error">{errors.confirm_password}</div>
              )}
            </div>
            <div className="form-group mb-2">
              <button
                className="w-full custom-active-button rounded-lg"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Topbar;

// Top bar component done //
