
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  profileList: [],
  isLoading: false,
  error: null,
};

// Manage Profile Details here //
interface ProfileDetails {
  // school_name: string;
  // client_id: string;
  // school_admin_name: string;
  // email: string;
}

// manage Profile Async here //
export const createProfileAsync = createAsyncThunk(
  "profile/list",
  async (credentials: ProfileDetails, { rejectWithValue }) => {

    // Retrieve tokens from localStorage
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/profile/get-me`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${SToken || token || reportToken}`,
            "db-token": Sdb_token || db_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// manage Profile Slice here //

const manageProfileSlice = createSlice({
  name: "profileState",
  initialState,
  reducers: {
    profileData: (state: any, action) => {
      state.profileList.push(action.payload);
    },
    resetProfileList: () => initialState,
    clearProfileError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProfileAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createProfileAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.profileList = [...state.profileList, action.payload];
      })
      .addCase(createProfileAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetProfileList, clearProfileError } =
  manageProfileSlice.actions;
export default manageProfileSlice.reducer;

export const selectProfileDataList = (state: RootState) =>
  state.profileState?.profileList ?? [];




// // update profile values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateProfileDetails {
  [x: string]: any;
  logo_url: string;
  contact_person: string;
  phone: string;
  landline: string;
}

interface UpdateProfileState {
  updateProfileList: UpdateProfileDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateProfileState = {
  updateProfileList: [],
  isLoading: false,
  error: null,
};

// Update Profile Async here //
export const updateProfileAsync = createAsyncThunk(
  "profile/update",
  async (profileData: UpdateProfileDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");


    try {
      const response = await axios.post(
        `${API_BASE_URL}/profile/update`,
        profileData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.updatedClassesData;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update profile slice here //
export const updateProfileSlice = createSlice({
  name: "updateProfileState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedProfile: (state) => {
      state.updateProfileList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateProfileAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateProfileAsync.fulfilled,
        (state, action: PayloadAction<UpdateProfileDetails>) => {
          state.isLoading = false;
          const index = state.updateProfileList.findIndex(
            (profile) => profile.id === action.payload.id
          );
          if (index !== -1) {
            state.updateProfileList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateProfileAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedProfile } = updateProfileSlice.actions;
export const updateProfileReducer = updateProfileSlice.reducer;
export const selectUpdateProfileDataList = (state: RootState) => {
  return state.updateProfileState?.updateProfileList ?? [];
};
